<template>
  <div class="blockchain">
    <Header />
    <FirmwareUpgrade />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Footer from '../components/common/Footer.vue'
import Header from '../components/common/Header.vue'
import FirmwareUpgrade from '../components/firmwareUpgrade/FirmwareUpgrade.vue'
export default {
  components: { Header, FirmwareUpgrade, Footer },
}
</script>
<style lang="scss" scoped>
.blockchain {
  .content {
    max-width: 11rem;
  }
}
</style>
