<template>
  <div class="video-animate">
    <main>
      <div class="sticky">
        <div class="media-wrapper">
          <section class="first-box">
            <div class="secret">{{ $t('safebox.title_1_1') }}</div>
            <div class="secret secret-2">
              {{ $t('safebox.title_1_2') }}
            </div>
            <div class="desc-button">
              <div class="desc">{{ $t('safebox.desc_1_2') }}</div>
              <div class="buy-button" @click="onBuy">
                <img src="../../assets/images/safebox/buy.png" />
                <span>{{ $t('home.shopNow') }}</span>
              </div>
            </div>
          </section>
          <canvas
            v-show="frameIndex !== 0"
            width="1920"
            height="1080"
            id="canvas"
            :class="{ large: isLarge }"
          ></canvas>
        </div>
      </div>
      <div class="timeline-wrapper">
        <section class="timeline-1">
          <div class="internal-structure-wrap">
            <div
              class="internal-structure d-flex ai-center"
              :class="{ show1: show1 }"
            >
              <div class="left-text">
                <div class="title">{{ $t('safebox.title_2_1') }}</div>
                <div class="title">{{ $t('safebox.title_2_2') }}</div>
                <ul
                  class="desc-wrap"
                  v-if="$i18n.locale === 'en'"
                  style="margin-left: 22px"
                >
                  <li class="desc">
                    Adopt full laser production process, thicken the thickness
                    of the panel and enhance the protection ability
                  </li>
                  <li class="desc">
                    More screw locks, more safety locks, and tighter fit
                  </li>
                  <li class="desc">
                    Support 2 sets of 12-bit mnemonic at the same time
                  </li>
                </ul>
                <div class="desc-wrap" v-else-if="$i18n.locale === 'zh'">
                  <div class="desc">
                    采用全激光制作工艺，加厚面板厚度，增强防护能力
                  </div>
                  <div class="desc">
                    更多螺丝锁，更多安全锁，让面板间更紧密贴合
                  </div>
                  <div class="desc">同时支持2套12位助记词</div>
                  <div class="desc">贴心让你安全放心</div>
                </div>
                <div class="desc-wrap" v-else>
                  <div class="desc">
                    採用全激光製作工藝，加厚面板厚度，增強防護能力
                  </div>
                  <div class="desc">
                    更多螺絲鎖，更多安全鎖，讓面板間更緊密貼合
                  </div>
                  <div class="desc">同時支持2套12位助記詞</div>
                  <div class="desc">貼心讓你安全放心</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="timeline-2">
          <div class="internal-structure-wrap mobile-video">
            <canvas width="750" height="422" id="canvas-2"></canvas>
            <div class="internal-structure d-flex ai-center">
              <div class="left-text">
                <div class="title">{{ $t('safebox.title_2_1') }}</div>
                <div class="desc">{{ $t('safebox.desc_2_4') }}</div>
              </div>
            </div>
          </div>
          <div class="three-characteristics">
            <div class="three-characteristics-wrap">
              <!-- <img
                class="big-img"
                src="../../assets/images/safebox/big-img.png"
                alt=""
              /> -->
              <div class="title">{{ $t('safebox.title_3_1_1') }}</div>
              <div class="title">{{ $t('safebox.title_3_1_2') }}</div>
              <div class="desc" v-if="$i18n.locale === 'en'">
                The whole body is precisely manufactured with 304 stainless
                steel which gives it fireproof, heat resistance and
                anti-corrosion features
              </div>
              <div class="desc" v-else-if="$i18n.locale === 'zh'">
                <div>
                  通体采用精制304不锈钢材料，让密盒在日常存储、使用中得到全方位保护
                </div>
                <div>并在极端环境下，展现惊人的防护能力</div>
                <div>做您的资产安全守护者</div>
              </div>
              <div class="desc" v-else>
                <div>
                  通体采用精制304不锈钢材料，让密盒在日常存储、使用中得到全方位保护
                </div>
                <div>并在极端环境下，展现惊人的防护能力</div>
                <div>做您的资产安全守护者</div>
              </div>
              <div class="characteristic-list">
                <div
                  class="characteristic-item"
                  v-for="(item, index) in characteristicList"
                  :key="index"
                >
                  <div class="order">{{ '0' + (index + 1) }}</div>
                  <img :src="item.img" />
                  <div class="text1">{{ item.text1 }}</div>
                  <div class="text2-wrap">
                    <div class="text2">{{ item.text2 }}</div>
                    <div class="text2">{{ item.text3 }}</div>
                    <div class="text2">{{ item.text4 }}</div>
                    <div class="text2">{{ item.text5 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="timeline-3">
          <div class="character-board" ref="character-board">
            <div class="board-left">
              <div class="title">{{ $t('safebox.title_4_1') }}</div>
              <div class="subtitle">{{ $t('safebox.desc_4_1_1') }}</div>
              <div class="subtitle subtitle-2">
                {{ $t('safebox.desc_4_1_2') }}
              </div>
              <div class="desc">{{ $t('safebox.desc_4_2_1') }}</div>
              <div class="desc desc-2">{{ $t('safebox.desc_4_2_2') }}</div>
              <div class="desc desc-2">{{ $t('safebox.desc_4_2_3') }}</div>
              <div class="list">
                <div
                  class="item"
                  v-for="(item, index) in boardList"
                  :key="index"
                >
                  <img :src="item.img" />
                  <span>{{ item.text1 }}</span>
                </div>
              </div>
            </div>
            <img
              class="board-img"
              :class="{ boardAni: boardAni }"
              src="../../assets/images/safebox/character-board.png"
            />
          </div>
        </section>
        <section class="timeline-4">
          <div class="operation-diagram-wrap">
            <div class="operation-diagram">
              <div class="title">{{ $t('safebox.title_5_1') }}</div>
              <div class="desc">{{ $t('safebox.desc_5_1') }}</div>
              <div class="video-mobile-wrap">
                <video
                  id="secret-video"
                  :src="headerVideo"
                  controls
                  x5-video-player-fullscreen="true"
                  x5-video-player-type="h5"
                  playsinline="true"
                  webkit-playsinline="true"
                ></video>
                <!-- <div class="play-wrap" v-if="mobilePlayBtn">
                  <img
                    src="../../assets/images/safebox/play.png"
                    @click="playVideoMobile"
                    alt=""
                  />
                </div> -->
              </div>
              <div class="step-list" ref="list">
                <div class="video-wrap">
                  <video
                    id="secret-video1"
                    :src="headerVideo"
                    controls
                    x5-video-player-fullscreen="true"
                    x5-video-player-type="h5"
                    playsinline="true"
                    webkit-playsinline="true"
                  ></video>
                  <div class="play-wrap" v-if="pcPlayBtn">
                    <img
                      src="../../assets/images/safebox/play.png"
                      @click="playVideoPc"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  class="step-item"
                  v-for="(item, index) in stepList"
                  :key="index"
                >
                  <img :src="item.img" />
                  <div class="step-order">{{ item.step }}</div>
                  <div class="label">{{ item.label }}</div>
                </div>
              </div>
              <img
                class="arrow arrow-left"
                src="../../assets/images/safebox/arrow-left.png"
                ref="leftArrow0"
                @click="moveScroll(false)"
              />
              <img
                class="arrow arrow-right"
                src="../../assets/images/safebox/arrow-left.png"
                ref="leftArrow1"
                @click="moveScroll(true)"
              />
            </div>
          </div>
        </section>
        <section class="timeline-5">
          <div class="specification-package">
            <div class="title">{{ $t('safebox.title_6_1') }}</div>
            <div class="specification-package-list">
              <div
                class="specification-package-item"
                v-for="(item, index) in packageList"
                :key="index"
              >
                <img class="main-img" :src="item.img" />
                <div class="d-flex ai-center">
                  <img class="icon" :src="item.icon" />
                  <span>{{ item.text1 }}</span>
                </div>
              </div>
            </div>
            <div class="box-panel">
              <div class="box">
                <div class="box-title size">{{ $t('safebox.desc_7_1') }}</div>
                <div class="size-wrap">
                  <div class="size">{{ $t('safebox.desc_7_2') }}</div>
                  <div class="size">105mm*60mm*6mm</div>
                </div>
                <div class="line"></div>
                <div class="size-wrap weight">
                  <div class="size">{{ $t('safebox.desc_7_3') }}</div>
                  <div class="size">205g</div>
                </div>
              </div>
              <div class="box">
                <div class="box-title size">{{ $t('safebox.desc_7_4') }}</div>
                <div class="size-wrap">
                  <div class="size">{{ $t('safebox.desc_7_2') }}</div>
                  <div class="size">106mm*87mm*1mm</div>
                </div>
                <div class="line"></div>
                <div class="size">{{ $t('safebox.desc_7_5') }}</div>
              </div>
            </div>
          </div>
          <div class="buy-button" @click="onBuy">
            <img src="../../assets/images/safebox/buy.png" />
            <span>{{ $t('home.shopNow') }}</span>
          </div>
          <Footer />
        </section>
      </div>
    </main>
  </div>
</template>

<script>
// ffmpeg -f image2 -i img-%d.png output.webm ffmpeg图片转webm视频脚本
// ffmpeg -f image2 -i video-%d.png output.webm ffmpeg图片转webm视频脚本
import Header from '../common/Header.vue';
import Footer from '../common/Footer.vue';
export default {
  components: { Header, Footer },
  data() {
    return {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      x3: 0,
      y3: 0,
      ctx: null,
      ctx2: null,
      show1: false,
      frameIndex: 0,
      boardAni: false,
      images: [],
      mobileImages: [],
      framesInternalImages: [],
      canvasWidth: 1920,
      canvasHeight: 1080,
      canvas2Width: 750,
      canvas2Height: 422,
      isMobile: false,
      isLarge: false,
      pcPlayBtn: true,
      mobilePlayBtn: true,
    };
  },
  created() {
    for (let index = 1; index < 159; index++) {
      let img = require(`../../assets/video/frames/img-${index}.png`);
      const elImg = document.createElement('img');
      elImg.src = img;
      elImg.style.display = 'none';
      window.document.body.appendChild(elImg);
      this.images.push(elImg);
    }
    for (let index = 1; index < 67; index++) {
      let img = require(`../../assets/video/mobile-frames/mobile-${index}.png`);
      const elImg = document.createElement('img');
      elImg.src = img;
      elImg.style.display = 'none';
      window.document.body.appendChild(elImg);
      this.mobileImages.push(elImg);
    }
    for (let index = 1; index < 31; index++) {
      let img = require(`../../assets/video/frames-internal/video-${index}.png`);
      const elImg = document.createElement('img');
      elImg.src = img;
      elImg.style.display = 'none';
      window.document.body.appendChild(elImg);
      this.framesInternalImages.push(elImg);
    }

    this.$nextTick(() => {
      this.ctx = document.getElementById('canvas').getContext('2d');
      this.ctx2 = document.getElementById('canvas-2').getContext('2d');
      if (this.ctx) {
        this.setCanvas();
      }
      window.addEventListener('scroll', this.scroll);
      window.addEventListener('resize', this.onResize);
    });
  },
  mounted() {
    // console.log('framesData', this.framesData);
    this.$nextTick(() => {
      this.initIntersectionObserver();
      // const video = document.querySelector('#secret-video1');
      // console.log('playVideoPc', video);
      // video.addEventListener('canplay', (event) => {
      //   console.log('video-play');
      //   video.play();
      // });
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll);
    window.removeEventListener('resize', this.onResize);
    this.intersectionObserver.disconnect();
  },
  computed: {
    langImg() {
      let lang = '-en';
      if (this.$i18n.locale === 'zh') {
        lang = '-zh';
      }
      return lang;
    },
    headerVideo() {
      return `https://hkg.bcebos.com/tp-statics-hk/secret-box/Secret-Box${this.langImg}.mp4`;
    },
    characteristicList() {
      return [
        {
          img: require('../../assets/images/safebox/features-1.png'),
          text1: this.$t('safebox.desc_3_2'),
          text2: this.$t('safebox.desc_3_3_1'),
          text3: this.$t('safebox.desc_3_3_2'),
          text4: this.$t('safebox.desc_3_3_3'),
          text5: this.$t('safebox.desc_3_3_4'),
        },
        {
          img: require('../../assets/images/safebox/features-2.png'),
          text1: this.$t('safebox.desc_3_4'),
          text2: this.$t('safebox.desc_3_5_1'),
          text3: this.$t('safebox.desc_3_5_2'),
          text4: this.$t('safebox.desc_3_5_3'),
          text5: this.$t('safebox.desc_3_5_4'),
        },
        {
          img: require('../../assets/images/safebox/features-3.png'),
          text1: this.$t('safebox.desc_3_6'),
          text2: this.$t('safebox.desc_3_7_1'),
          text3: this.$t('safebox.desc_3_7_2'),
          text4: this.$t('safebox.desc_3_7_3'),
          text5: this.$t('safebox.desc_3_7_4'),
        },
      ];
    },
    boardList() {
      return [
        {
          img: require('../../assets/images/safebox/board-1.png'),
          text1: this.$t('safebox.desc_4_3'),
        },
        {
          img: require('../../assets/images/safebox/board-2.png'),
          text1: this.$t('safebox.desc_4_4'),
        },
        {
          img: require('../../assets/images/safebox/board-3.png'),
          text1: this.$t('safebox.desc_4_5'),
        },
        {
          img: require('../../assets/images/safebox/board-4.png'),
          text1: this.$t('safebox.desc_4_6'),
        },
      ];
    },
    stepList() {
      return [
        {
          img: require('../../assets/images/safebox/step-1.png'),
          step: this.$t('safebox.desc_5_2'),
          label: this.$t('safebox.desc_5_3'),
        },
        {
          img: require('../../assets/images/safebox/step-2.png'),
          step: this.$t('safebox.desc_5_4'),
          label: this.$t('safebox.desc_5_5'),
        },
        {
          img: require('../../assets/images/safebox/step-3.png'),
          step: this.$t('safebox.desc_5_6'),
          label: this.$t('safebox.desc_5_7'),
        },
        {
          img: require('../../assets/images/safebox/step-4.png'),
          step: this.$t('safebox.desc_5_8'),
          label: this.$t('safebox.desc_5_9'),
        },
        {
          img: require('../../assets/images/safebox/step-5.png'),
          step: this.$t('safebox.desc_5_10'),
          label: this.$t('safebox.desc_5_11'),
        },
      ];
    },
    packageList() {
      return [
        {
          img: require('../../assets/images/safebox/package-1.png'),
          icon: require('../../assets/images/safebox/num-1.png'),
          text1: this.$t('safebox.desc_6_1'),
          // text2: this.$t('safebox.desc_6_2'),
        },
        {
          img: require('../../assets/images/safebox/package-2.png'),
          icon: require('../../assets/images/safebox/num-2.png'),
          text1: this.$t('safebox.desc_6_3'),
          // text2: this.$t('safebox.desc_6_4'),
        },
        {
          img: require('../../assets/images/safebox/package-3.png'),
          icon: require('../../assets/images/safebox/num-3.png'),
          text1: this.$t('safebox.desc_6_5'),
          // text2: this.$t('safebox.desc_6_6'),
        },
        {
          img: require('../../assets/images/safebox/package-4.png'),
          icon: require('../../assets/images/safebox/num-4.png'),
          text1: this.$t('safebox.desc_6_7'),
          // text2: this.$t('safebox.desc_6_8'),
        },
      ];
    },
    usageList() {
      return [
        {
          text1: this.$t('safebox.desc_7_6'),
          text2: this.$t('safebox.desc_7_7'),
        },
        {
          text1: this.$t('safebox.desc_7_8'),
          text2: this.$t('safebox.desc_7_9'),
        },
        {
          text1: this.$t('safebox.desc_7_10'),
          text2: this.$t('safebox.desc_7_11'),
        },
      ];
    },
  },

  methods: {
    playVideoPc() {
      const video = document.querySelector('#secret-video1');
      if (video) {
        video.play();
        this.pcPlayBtn = false
      }
    },
    playVideoMobile() {
      const video = document.querySelector('#secret-video');
      if (video) {
        video.play();
        this.mobilePlayBtn = false
      }
    },
    // 滚动条左右移动,匀速移动效果
    moveScroll(flag) {
      if (!this.isEnable) {
        this.isEnable = true;
        let moveDis = 1000;
        let timeMove = 4;
        if (!flag) timeMove = -4;
        // console.log(this.$refs)
        let count = 0;
        let timer = setInterval(() => {
          this.$refs.list.scrollLeft += timeMove;
          // 判断滚动条是否滚动到底部
          if (
            this.$refs.list.scrollWidth <=
            this.$refs.list.offsetWidth + this.$refs.list.scrollLeft
          ) {
            clearInterval(timer);
            this.isEnable = false;
          }
          count += 10;
          if (count >= moveDis) {
            this.isEnable = false;
            clearInterval(timer);
          }
        }, 10);
      }
    },
    initIntersectionObserver() {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entrie) => {
            if (entrie.intersectionRatio >= 0.3) {
              // 可见百分比
              if (entrie.target.className === 'character-board') {
                this.boardAni = true;
              }
              // 只在挂载的时候执行一次
              // this.intersectionObserver.unobserve(entrie.target)
            } else if (entrie.intersectionRatio === 0) {
              // 完全不可见
              if (entrie.target.className === 'character-board') {
                this.boardAni = false;
              }
            }
          });
        },
        { threshold: [0, 0.3] }
      );

      if (this.$refs) {
        Object.values(this.$refs).forEach((ele) => {
          this.intersectionObserver.observe(ele);
        });
      }
    },
    onBuy() {
      let url =
        'https://www.keypal.store/products/keypal-mnemonic-secret-box-pro';
      if (this.$i18n.locale === 'zh') {
        url =
          'https://shop95838799.m.youzan.com/v2/showcase/tag?alias=2frz8182d8zgn&ps=760';
      }
      // window.location.href = url;
      window.open(url)
    },
    scroll() {
      if (this.isMobile) {
        this.onScroll(this.ctx, this.mobileImages);
      } else {
        this.onScroll(this.ctx, this.images);
      }
    },
    onResize() {
      if (this.ctx) {
        this.setCanvas();
      }
    },
    setCanvas() {
      let dpr =
        window.devicePixelRatio ||
        window.webkitDevicePixelRatio ||
        window.mozDevicePixelRatio ||
        1;
      if (window.innerWidth < 767) {
        this.ctx2.canvas.width = this.canvas2Width = 750 * dpr;
        this.ctx2.canvas.height = this.canvas2Height = 422 * dpr;
        this.ctx.canvas.width = this.canvasWidth = 750 * dpr;
        this.ctx.canvas.height = this.canvasHeight = 1334 * dpr;
        this.isMobile = true;
      } else {
        this.ctx.canvas.width = this.canvasWidth = 1920 * dpr;
        this.ctx.canvas.height = this.canvasHeight = 1080 * dpr;
        this.isMobile = false;
        if (dpr > 1) {
          this.isLarge = true;
        }
      }
    },
    onScroll(ctx, frames) {
      this.frameIndex = parseInt(document.documentElement.scrollTop / 10);
      // console.log('frameIndex', this.frameIndex);
      // console.log('scrollTop', document.documentElement.scrollTop);

      // 3. draw video frame
      if (frames[this.frameIndex] !== undefined) {
        // renderFrame(ctx, frames[this.frameIndex]);
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        ctx.drawImage(
          frames[this.frameIndex],
          0,
          0,
          this.canvasWidth,
          this.canvasHeight
        );
      }

      // 4. update page scroll
      this.updateAnimation(this.frameIndex);
    },
    // 根据当前的显示的哪一帧的图片来确定当前应该显示什么内容
    updateAnimation(frameIndex) {
      const sticky = document.querySelector('.sticky');
      const mediaWrapper = document.querySelector('.media-wrapper');
      const secret = document.querySelector('.secret');
      const secret2 = document.querySelector('.secret-2');
      const descButton = document.querySelector('.desc-button');
      const timeline2 = document.querySelector('.timeline-2');
      const mobileVideo = document.querySelector('.mobile-video');
      const canvas = document.querySelector('#canvas');
      const canvas2 = document.querySelector('#canvas-2');

      if (frameIndex < 35) {
        secret.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
        secret2.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
        descButton.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
        mediaWrapper.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
        timeline2.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
        // canvas.style.display = 'block';
        sticky.style['pointer-events'] = 'auto';
      } else if (frameIndex >= 35 && frameIndex <= 64) {
        canvas.style.display = 'block';
        sticky.style['pointer-events'] = 'auto';
        if (this.isMobile) {
          this.x1 = 0;
          this.x2 = 0;
          this.x3 = 0;
          this.y1 = this.y2 = this.y3 = (frameIndex - 35) * 5;
        } else if (this.$i18n.locale === 'en') {
          this.x1 = (frameIndex - 35) * 12;
          this.y1 = 0;
          this.x2 = (frameIndex - 35) * 10;
          this.y2 = (frameIndex - 35) * 5.5;
          this.x3 = 0;
          this.y3 = (frameIndex - 35) * 5;
        } else {
          this.x1 = (frameIndex - 35) * 7;
          this.y1 = 0;
          this.x2 = (frameIndex - 35) * 8.5;
          this.y2 = (frameIndex - 35) * 5.5;
          this.x3 = 0;
          this.y3 = (frameIndex - 35) * 5;
        }
        // 左边Secret
        secret.style.transform = `matrix(1, 0, 0, 1, -${this.x1}, -${this.y1}`;
        // 右边 safe box
        secret2.style.transform = `matrix(1, 0, 0, 1, ${this.x2}, -${this.y2})`;
        // desc button
        descButton.style.transform = `matrix(1, 0, 0, 1, 0, -${this.y3}`;
      } else if (frameIndex > 64) {
        // 如果PC端滑动速度很快就直接计算第64帧的时候的x,y
        if (!this.isMobile) {
          if (this.$i18n.locale === 'en') {
            this.x1 = (64 - 35) * 12;
            this.y1 = 0;
            this.x2 = (64 - 35) * 10;
            this.y2 = (64 - 35) * 5.5;
            this.x3 = 0;
            this.y3 = (64 - 35) * 5;
          } else {
            this.x1 = (64 - 35) * 7;
            this.y1 = 0;
            this.x2 = (64 - 35) * 8.5;
            this.y2 = (64 - 35) * 5.5;
            this.x3 = 0;
            this.y3 = (64 - 35) * 5;
          }
        }
        sticky.style['pointer-events'] = 'auto';
        // 左边Secret
        secret.style.transform = `matrix(1, 0, 0, 1, -${this.x1}, -${
          this.y1 + (frameIndex - 64) * 17
        }`;
        // 右边 safe box
        secret2.style.transform = `matrix(1, 0, 0, 1, ${this.x2}, -${
          this.y2 + (frameIndex - 64) * 17
        })`;
        // desc button
        descButton.style.transform = `matrix(1, 0, 0, 1, 0, -${
          this.y3 + (frameIndex - 64) * 17
        }`;
      }

      // 移动端动效
      if (this.isMobile) {
        if (frameIndex > 0 && frameIndex <= 64) {
          canvas.style.display = 'block';
          canvas2.style.opacity = 0;
          mobileVideo.style.transform = `matrix(1, 0, 0, 1, 0, 500`;
        } else if (frameIndex > 70) {
          sticky.style['pointer-events'] = 'none';
          canvas.style.display = 'none';
          canvas2.style.opacity = 1;
          mobileVideo.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
          mobileVideo.style.transition = 'transform 0.2s';
          if (frameIndex > 78 && frameIndex < 108) {
            this.ctx2.clearRect(0, 0, this.canvas2Width, this.canvas2Height);
            this.ctx2.drawImage(
              this.framesInternalImages[this.frameIndex - 78],
              0,
              0,
              this.canvas2Width,
              this.canvas2Height
            );
          }
        }
      } else {
        if (frameIndex >= 98 && frameIndex < 150) {
          this.show1 = true;
          canvas.style.display = 'block';
          sticky.style['pointer-events'] = 'none';
        } else {
          this.show1 = false;
          if (frameIndex >= 150) {
            sticky.style['pointer-events'] = 'none';
            canvas.style.display = 'none';
          }
        }
      }

      if (frameIndex >= 0 && frameIndex <= 150 && !this.isMobile) {
        timeline2.style.transform = `matrix(1, 0, 0, 1, 0, 800`;
        mediaWrapper.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
      } else if (frameIndex > 150 && !this.isMobile) {
        timeline2.style.transform = `matrix(1, 0, 0, 1, 0, 0`;
        timeline2.style.transition = 'transform 0.2s';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-animate {
  .buy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.15rem 0.8rem;
    background: #000000;
    border-radius: 0.44rem;
    width: fit-content;
    margin: 0 auto;
    margin-top: 0.3rem;
    cursor: pointer;
    img {
      width: 0.32rem;
    }
    span {
      margin-left: 0.1rem;
      font-size: 0.16rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
  main {
    position: relative;
    .sticky {
      // position: sticky;
      position: fixed;
      width: 100%;
      z-index: 100;
      top: 0px;
      height: 100vh;
      overflow: hidden;

      .content-wrapper {
        position: absolute;
        width: 100%;
        z-index: -1;
      }

      .media-wrapper {
        display: flex;
        height: 100vh;
        width: 100%;
        left: 50%;
        align-items: flex-end;
        justify-content: center;

        .first-box {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          // height: 100vh;
          .secret {
            // background-image: linear-gradient(#1a202c 100%, #9d9d9d 100%);
            font-size: 1.76rem;
            font-weight: 700;
            text-align: center;
            letter-spacing: 0.18px;
            // -webkit-background-clip: text;
            // color: transparent;
            line-height: 1.6rem;
          }
          .secret-2 {
            background-image: linear-gradient(
              #ffffff 0%,
              #92fff8 20%,
              #236cff 70%
            );
            -webkit-background-clip: text;
            color: transparent;
          }
          .desc {
            margin-top: 0.48rem;
            font-size: 0.2rem;
            text-align: center;
            color: #7a7a7a;
          }
        }

        canvas {
          display: block;
          position: absolute;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none;
          &.large {
            height: 100%;
          }
        }
      }
    }

    .timeline-wrapper {
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      section {
        width: 100%;
      }
      .internal-structure-wrap {
        position: fixed;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        pointer-events: none;
        canvas {
          display: none;
        }
        .video-internal {
          display: none;
        }
        .internal-structure {
          width: 12rem;
          margin: 0 auto;
          opacity: 0;
          transform: translateY(20px);
          &.show1 {
            transition: opacity 0.5s, transform 0.5s;
            transform: translateY(0);
            opacity: 1;
          }
          .title {
            max-width: 5rem;
            font-size: 0.56rem;
            font-weight: 600;
            color: #000000;
            line-height: 0.72rem;
          }
          .desc-wrap {
            margin-top: 0.21rem;
          }
          .desc {
            max-width: 4.38rem;
            font-size: 0.16rem;
            color: rgba(0, 0, 0, 0.6);
            line-height: 0.3rem;
          }
          .right-wrap {
            flex: 1;
            height: 100vh;
            position: relative;
            img {
              position: absolute;
            }
            .baffle {
              width: 0.44rem;
            }
            .baffle-1 {
              top: 25%;
              left: 20%;
            }
            .baffle-A {
              width: 3.96rem;
              top: 70%;
              left: 10%;
            }
            .baffle-B {
              width: 1.37rem;
              top: 70%;
              right: 10%;
            }
          }
        }
      }
      .timeline-1 {
        height: 16.5rem;
      }
      .timeline-2 {
        .internal-structure-wrap {
          display: none;
        }
        .three-characteristics {
          width: 100%;
          background: #000;
          .three-characteristics-wrap {
            width: 12rem;
            margin: 0 auto;
            position: relative;
            padding: 1.92rem 0;
            canvas {
              display: none;
            }
            .big-img {
              width: 4rem;
              position: absolute;
              top: 1.6rem;
              right: 0;
            }
            .title {
              width: 5rem;
              font-size: 0.56rem;
              font-weight: 600;
              color: #ffffff;
              line-height: 0.72rem;
            }
            .desc {
              margin-top: 0.21rem;
              width: 7.5rem;
              font-size: 0.16rem;
              color: rgba(255, 255, 255, 0.6);
              line-height: 0.3rem;
            }
            .characteristic-list {
              display: flex;
              justify-content: space-between;
              margin-top: 0.65rem;
              .characteristic-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 3.8rem;
                padding: 0.6rem 0.25rem;
                background: #1f1f1f;
                border-radius: 0.41rem;
                .order {
                  font-size: 0.32rem;
                  color: #ffffff;
                }
                img {
                  width: 2.44rem;
                  margin: 0 auto;
                }
                .text1 {
                  margin-top: 0.2rem;
                  font-size: 0.32rem;
                  color: #ffffff;
                  text-align: center;
                }
                .text2-wrap {
                  margin-top: 0.2rem;
                }
                .text2 {
                  text-align: center;
                  font-size: 0.16rem;
                  color: rgba(255, 255, 255, 0.6);
                  line-height: 0.3rem;
                }
              }
            }
          }
        }
      }
      .timeline-3 {
        padding: 2.37rem 0 2.37rem;
        overflow: hidden;
        .character-board {
          width: 12rem;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .board-left {
            .title {
              font-size: 0.56rem;
              font-weight: 600;
              color: #000000;
            }
            .subtitle {
              margin-top: 0.24rem;
              width: 4.73rem;
              font-size: 0.26rem;
              color: #000000;
              line-height: 0.35rem;
            }
            .subtitle-2 {
              margin-top: 0;
            }
            .desc {
              margin-top: 0.16rem;
              width: 5.2rem;
              font-size: 0.14rem;
              color: rgba(0, 0, 0, 0.56);
              line-height: 0.24rem;
            }
            .desc-2 {
              margin-top: 0;
            }
            .list {
              margin-top: 0.48rem;
              display: flex;
              .item {
                width: 0.61rem;
                display: flex;
                flex-direction: column;
                &:nth-child(n + 2) {
                  margin-left: 0.32rem;
                }

                img {
                  width: 0.61rem;
                }
                span {
                  margin-top: 0.12rem;
                  font-size: 0.08rem;
                  color: #000000;
                  line-height: 0.16rem;
                  word-break: break-word;
                  text-align: center;
                }
              }
            }
          }
          .board-img {
            width: 5.45rem;
            transform: translateX(8rem);
            opacity: 0;
            &.boardAni {
              opacity: 1;
              transform: translateX(0);
              transition: transform 0.5s;
            }
          }
        }
      }
      .timeline-4 {
        pointer-events: auto;
        .operation-diagram-wrap {
          width: 100%;
          background: #f5f6f7;
          padding: 1.44rem 0 1.08rem;
          .operation-diagram {
            position: relative;
            width: 12rem;
            margin: 0 auto;
            .title {
              font-size: 0.48rem;
              font-weight: 600;
              color: #000000;
            }
            .desc {
              margin-top: 0.24rem;
              width: 5.56rem;
              font-size: 0.16rem;
              color: rgba(0, 0, 0, 0.6);
              line-height: 0.3rem;
            }
            .desc-2 {
              margin-top: 0;
            }
            .video-mobile-wrap {
              position: relative;
              display: none;
            }
            .play-wrap {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.67);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0.32rem;
              img {
                width: 0.6rem;
                height: 0.6rem;
              }
            }
            // #video-mobile {
            // }
            .step-list {
              margin-top: 0.6rem;
              display: flex;
              overflow-x: auto;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
              .video-wrap {
                position: relative;
                height: 4.55rem;
                width: 8.08rem;
                font-size: 0;
                #secret-video1 {
                  border-radius: 0.32rem;
                  height: 100%;
                  width: 8.08rem;
                }
              }
              .step-item {
                width: 3.13rem;
                background: #ffffff;
                border-radius: 0.32rem;
                padding: 0.48rem 0;
                &:nth-child(n + 2) {
                  margin-left: 0.32rem;
                }
                img {
                  width: 3.13rem;
                }
                .step-order {
                  padding: 0 0.32rem;
                  font-size: 0.28rem;
                  color: #f14a3d;
                }
                .label {
                  margin-top: 0.16rem;
                  padding: 0 0.32rem;
                  font-size: 0.18rem;
                  color: #000000;
                  line-height: 0.26rem;
                }
              }
            }
            .arrow {
              position: absolute;
              z-index: 100;
              top: 3.8rem;
              width: 0.7rem;
            }
            .arrow-left {
              left: -0.4rem;
            }
            .arrow-right {
              right: -0.4rem;
              transform: rotate(180deg);
            }
          }
        }
      }
      .timeline-5 {
        .specification-package {
          padding: 1.32rem 0 0.64rem;
          .title {
            width: 5.15rem;
            margin: 0 auto;
            font-size: 0.48rem;
            font-weight: 600;
            text-align: center;
            color: #000000;
          }
          .specification-package-list {
            width: 12rem;
            margin: 0 auto;
            margin-top: 0.8rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .specification-package-item {
              background: #f7f8fa;
              border-radius: 0.35rem;
              width: 5.68rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-bottom: 0.93rem;
              &:nth-child(n + 3) {
                margin-top: 0.64rem;
              }
              .main-img {
                width: 5.68rem;
              }
              .icon {
                width: 0.28rem;
              }
              span {
                margin-left: 0.16rem;
                font-size: 0.28rem;
                font-weight: 700;
                color: #000000;
              }
              .text2 {
                padding: 0 0.56rem;
                margin-top: 0.24rem;
                font-size: 0.16rem;
                font-weight: 500;
                color: #828282;
                line-height: 0.24rem;
              }
            }
          }
          .box-panel {
            display: flex;
            justify-content: space-between;
            width: 12rem;
            margin: 0 auto;
            margin-top: 0.48rem;
            .box {
              padding: 0.32rem;
              background: #f7f8fa;
              border-radius: 0.39rem;
              width: 5.74rem;
              .size {
                font-size: 0.2rem;
                font-weight: 400;
                text-align: left;
                color: #000000;
              }
              .box-title {
                font-size: 0.26rem;
                font-weight: 600;
              }
              .size-wrap {
                margin-top: 0.3rem;
                display: flex;
                justify-content: space-between;
              }
              .weight {
                margin-top: 0;
              }
              .line {
                margin: 0.16rem 0;
                height: 0.02rem;
                background: #e6e8ea;
              }
            }
          }
        }
        .buy-button {
          margin-top: 0;
          margin-bottom: 1.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .video-animate {
    .buy-button {
      margin-top: 0.72rem;
      padding: 0.32rem 0.64rem;
      border-radius: 0.64rem;
      img {
        width: 0.5rem;
      }
      span {
        margin-left: 0.2rem;
        font-size: 0.32rem;
      }
    }
    main {
      .sticky {
        .media-wrapper {
          // display: none;
          .first-box {
            .secret {
              font-size: 1.56rem;
            }
            .desc {
              margin-top: 0.8rem;
              font-size: 0.4rem;
            }
          }
          canvas {
            width: auto;
            height: 100%;
          }
        }
      }
      .timeline-wrapper {
        .internal-structure-wrap {
          flex-direction: column;
          justify-content: center;
          canvas {
            width: 100%;
            display: block;
            &.show1 {
              display: block;
            }
          }
          .video-internal {
            width: 100%;
            &.show {
              display: block;
            }
          }
          .internal-structure {
            width: 100%;
            padding: 0 0.64rem;
            .title {
              font-size: 0.72rem;
              max-width: none;
              line-height: 0.91rem;
            }
            .desc {
              max-width: none;
              margin-top: 0.41rem;
              font-size: 0.32rem;
              line-height: 0.48rem;
            }
          }
        }
        .timeline-1 {
          height: 19rem;
          .internal-structure-wrap {
            display: none;
          }
        }
        .timeline-2 {
          .internal-structure-wrap {
            display: flex;
            position: static;
            .internal-structure {
              opacity: 1;
            }
          }
          .three-characteristics {
            .three-characteristics-wrap {
              width: 100%;
              padding: 2.26rem 0.64rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              canvas {
                width: 100%;
                display: block;
              }
              .big-img {
                position: static;
                width: 70%;
                margin: 2.26rem 0 1.2rem;
              }
              .title {
                font-size: 0.72rem;
                width: 100%;
                line-height: 0.91rem;
              }
              .desc {
                width: auto;
                font-size: 0.32rem;
                line-height: 0.48rem;
              }
              .characteristic-list {
                flex-direction: column;
                margin-top: 0.93rem;
                .characteristic-item {
                  width: 100%;
                  padding: 0.8rem 0.4rem 1rem;
                  &:nth-child(n + 2) {
                    margin-top: 0.81rem;
                  }
                  img {
                    width: 100%;
                  }
                  .order {
                    font-size: 0.53rem;
                  }
                  .text1 {
                    margin-top: 0.5rem;
                    font-size: 0.53rem;
                  }
                  .text2 {
                    font-size: 0.26rem;
                    line-height: 0.5rem;
                  }
                }
              }
            }
          }
        }
        .timeline-3 {
          .character-board {
            width: 100%;
            flex-direction: column;
            .board-img {
              width: 70%;
              transform: translateX(0);
              order: 1;
              opacity: 1;
            }
            .board-left {
              order: 10;
              width: 100%;
              padding: 0 0.64rem;
              margin-top: 2.2rem;
              .title {
                font-size: 0.72rem;
              }
              .subtitle {
                width: auto;
                margin-top: 0.32rem;
                font-size: 0.4rem;
                line-height: 0.6rem;
              }
              .subtitle-2 {
                margin-top: 0;
              }
              .desc {
                width: auto;
                margin-top: 0.32rem;
                font-size: 0.26rem;
                line-height: 0.39rem;
              }
              .desc-2 {
                margin-top: 0;
              }
              .list {
                margin-top: 0.75rem;
                .item {
                  width: 1.1rem;
                  &:nth-child(n + 2) {
                    margin-left: 0.58rem;
                  }
                  img {
                    width: 1.1rem;
                  }
                  span {
                    margin-top: 0.22rem;
                    font-size: 0.2rem;
                    line-height: 0.3rem;
                  }
                }
              }
            }
          }
        }
        .timeline-4 {
          .operation-diagram-wrap {
            padding: 1.44rem 0.64rem 1.08rem;
            .operation-diagram {
              width: 100%;
              .title {
                font-size: 0.6rem;
              }
              .desc {
                margin-top: 0.4rem;
                font-size: 0.32rem;
                line-height: 0.48rem;
              }
              .video-mobile-wrap {
                display: block;
                margin-top: 1rem;
                width: 100%;
                border-radius: 0.32rem;
                font-size: 0;
                video {
                  width: 100%;
                  border-radius: 0.32rem;
                }
              }
              .step-list {
                margin-top: 1.26rem;
                .video-wrap {
                  display: none;
                }
                .step-item {
                  width: auto;
                  min-width: 4rem;
                  padding: 0.6rem 0 1rem;
                  border-radius: 0.51rem;
                  &:nth-child(n + 2) {
                    margin-left: 0;
                  }
                  &:nth-child(n + 3) {
                    margin-left: 0.32rem;
                  }
                  img {
                    width: 100%;
                  }
                  .step-order {
                    font-size: 0.44rem;
                    padding: 0 0.5rem;
                  }
                  .label {
                    padding: 0 0.5rem;
                    font-size: 0.29rem;
                    line-height: 0.4rem;
                  }
                }
              }
              .arrow {
                display: none;
              }
            }
          }
        }
        .timeline-5 {
          .specification-package {
            padding: 1.6rem 0.64rem 2.1rem;
            .title {
              font-size: 0.72rem;
            }
            .specification-package-list {
              width: 100%;
              .specification-package-item {
                width: 100%;
                &:nth-child(n + 2) {
                  margin-top: 0.64rem;
                }
                .icon {
                  width: 0.4rem;
                }
                span {
                  font-size: 0.4rem;
                }
                .text2 {
                  margin-top: 0.4rem;
                  font-size: 0.24rem;
                  line-height: 0.36rem;
                  text-align: center;
                }
              }
            }
            .box-panel {
              width: 100%;
              flex-direction: column;
              .box {
                width: 100%;
                &:nth-child(2) {
                  margin-top: 0.5rem;
                }
                .size {
                  font-size: 0.28rem;
                }
                .box-title {
                  font-size: 0.32rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
