<template>
  <div class="keypal">
    <Header isKeyPal isKeyPalDetail />
    <BuyKeyPal :colorList="colorList" />
    <div class="content break-down">
      <div class="title main-title">KeyPal</div>
      <TransitionContainer :descList="descList" />
      <div class="easy-and-safe">
        <div class="title-wrap">
          <div class="title">{{ $t('keypal.easy_and_safe_title') }}</div>
          <div class="text desc">
            {{ $t('keypal.easy_and_safe_desc') }}
          </div>
        </div>
        <div class="list d-flex jc-between">
          <EasySafeItem
            v-for="(item, index) in easySafeList"
            :key="index"
            :obj="item"
          />
        </div>
      </div>
      <div class="easy-and-safe product-specifications">
        <div class="title-wrap">
          <div class="title">{{ $t('keypal.product_specifications') }}</div>
        </div>
        <OperationKeyPal />
        <div class="specification-wrap d-flex jc-between">
          <div class="specification-list d-flex flex-wrap jc-between">
            <div
              class="specification-item"
              v-for="(item, index) in specificationList"
              :key="index"
            >
              <div class="text title">{{ item.title }}</div>
              <div class="d-flex ai-center">
                <img v-if="item.img" :src="item.img" />
                <span class="text" :class="{ 'no-img': !item.img }">{{
                  item.desc
                }}</span>
              </div>
            </div>
          </div>
          <div class="package">
            <div class="title">{{ $t('keypal.package') }}</div>
            <div class="text">{{ $t('keypal.package_line_1') }}</div>
            <div class="text">{{ $t('keypal.package_line_2') }}</div>
            <div class="text">{{ $t('keypal.package_line_3') }}</div>
            <div class="text">{{ $t('keypal.package_line_4') }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer isKeyPal />
  </div>
</template>

<script>
import BuyKeyPal from '../components/common/BuyKeyPal.vue';
import EasySafeItem from '../components/common/EasySafeItem.vue';
import Footer from '../components/common/Footer.vue';
import Header from '../components/common/Header.vue';
import OperationKeyPal from '../components/common/OperationKeyPal.vue';
import TransitionContainer from '../components/common/TransitionContainer.vue';
export default {
  components: {
    Header,
    Footer,
    BuyKeyPal,
    OperationKeyPal,
    EasySafeItem,
    TransitionContainer,
  },
  data() {
    return {
      colorList: ['black'],
      // colorList: ['black', 'silver', 'golden'],
    };
  },
  computed: {
    descList() {
      return [
        {
          label: this.$t('keypal.break_down_desc_1'),
          text: this.$t('keypal.break_down_desc_2'),
        },
        {
          label: this.$t('keypal.break_down_desc_3'),
          text: this.$t('keypal.break_down_desc_4'),
        },
        {
          label: this.$t('keypal.break_down_desc_5'),
          text: this.$t('keypal.break_down_desc_6'),
        },
        {
          label: this.$t('keypal.break_down_desc_7'),
          text: this.$t('keypal.break_down_desc_8'),
        },
        {
          label: this.$t('keypal.break_down_desc_9'),
          text: this.$t('keypal.break_down_desc_10'),
        },
      ];
    },
    easySafeList() {
      return [
        {
          id: 0,
          img: require('../assets/images/keypal-plus/Dual-chip.png'),
          text1: this.$t('keypal.easy_and_safe_list_1'),
          text2: this.$t('keypal.easy_and_safe_list_2'),
        },
        {
          id: 1,
          img: require('../assets/images/keypal-plus/Easy-connection.png'),
          text1: this.$t('keypal.easy_and_safe_list_3'),
          text2: this.$t('keypal.easy_and_safe_list_4'),
        },
        {
          id: 2,
          img: require('../assets/images/keypal-plus/inch-display.png'),
          text1: this.$t('keypal.easy_and_safe_list_5'),
          text2: this.$t('keypal.easy_and_safe_list_6'),
        },
      ];
    },
    specificationList() {
      return [
        {
          img: require('../assets/images/specification/card-3-1.png'),
          title: this.$t('keypal.keypal_card_1'),
          desc: this.$t('keypal.keypal_card_label_1'),
        },
        {
          img: require('../assets/images/specification/card-2-1.png'),
          title: this.$t('keypal.keypal_card_2'),
          desc: this.$t('keypal.keypal_card_label_2'),
        },
        {
          img: require('../assets/images/specification/card-1-2.png'),
          title: this.$t('keypal.keypal_card_3'),
          desc: this.$t('keypal.keypal_card_label_3'),
        },
        {
          title: this.$t('keypal.keypal_card_4'),
          desc: this.$t('keypal.keypal_card_label_4'),
        },
        {
          img: require('../assets/images/specification/card-3-2.png'),
          title: this.$t('keypal.keypal_card_5'),
          desc: this.$t('keypal.keypal_card_label_5'),
        },
        {
          img: require('../assets/images/specification/card-4-1.png'),
          title: this.$t('keypal.keypal_card_6'),
          desc: this.$t('keypal.keypal_card_label_6'),
        },
        {
          img: require('../assets/images/specification/card-1-1.png'),
          title: this.$t('keypal.keypal_card_7'),
          desc: this.$t('keypal.keypal_card_label_7'),
        },
        {
          img: require('../assets/images/specification/card-2-2.png'),
          title: this.$t('keypal.keypal_card_8'),
          desc: this.$t('keypal.keypal_card_label_8'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.keypal {
  background: #fafafa;
  .break-down {
    padding: 1rem 0;
    .main-title {
      display: none;
    }
    .easy-and-safe {
      margin-top: 1rem;
      .title-wrap {
        max-width: 7.26rem;
        margin: 0 auto;
        padding: 0.2rem 1.5rem;
        border: 0.01rem solid #101010;
        border-radius: 0.08rem;
        .title {
          font-size: 0.2rem;
          line-height: 0.24rem;
        }
        .desc {
          margin-top: 0.08rem;
          font-size: 0.14rem;
          text-align: center;
          color: #444444;
          line-height: 0.17rem;
        }
      }
      .list {
        margin-top: 0.84rem;
      }
    }
    .product-specifications {
      margin-top: 1.3rem;
      .specification-wrap {
        margin-top: 1.2rem;
        padding: 0 0.8rem;
        .specification-list {
          .specification-item {
            width: 23%;
            &:nth-child(n + 5) {
              margin-top: 0.35rem;
            }
            .title {
              font-size: 0.14rem;
              text-align: left;
              line-height: 0.17rem;
              margin-bottom: 0.2rem;
            }
            img {
              width: 0.14rem;
            }
            span {
              margin-left: 0.06rem;
              font-size: 0.12rem;
              text-align: left;
              line-height: 0.15rem;
              &.no-img {
                margin-left: 0;
              }
            }
          }
        }
        .package {
          margin-left: 0.8rem;
          .title {
            font-size: 0.14rem;
            font-weight: 700;
            text-align: left;
            line-height: 0.17rem;
            margin-bottom: 0.16rem;
          }
          .text {
            white-space: nowrap;
            font-size: 0.12rem;
            text-align: left;
            line-height: 0.16rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .keypal {
    .break-down {
      padding: 1rem 0.32rem;
    }
  }
}
@media screen and (max-width: 767px) {
  .keypal {
    .break-down {
      padding: 0.78rem 0;
      .main-title {
        display: block;
        font-size: 0.44rem;
      }
      .easy-and-safe {
        .title-wrap {
          max-width: 75%;
          border: none;
          padding: 0;
          .title {
            font-size: 0.44rem;
            line-height: normal;
          }
          .desc {
            margin-top: 0.21rem;
            font-size: 0.26rem;
            line-height: 0.31rem;
          }
        }
        .list {
          padding: 0 0.62rem;
          margin-top: 0.58rem;
          flex-wrap: wrap;
        }
      }
      .product-specifications {
        .title-wrap {
          .title {
            font-size: 0.4rem;
          }
        }
        .specification-wrap {
          position: relative;
          padding: 0 0.5rem;
          .specification-list {
            .specification-item {
              width: 48%;
              &:nth-child(n + 3) {
                margin-top: 0.6rem;
              }
              &:nth-child(7) {
                width: 100%;
              }
              &:nth-child(1) {
                order: 1;
              }
              &:nth-child(2) {
                order: 5;
                margin-top: 0.6rem;
              }
              &:nth-child(3) {
                order: 9;
              }
              &:nth-child(4) {
                order: 2;
                margin-top: 0;
              }
              &:nth-child(5) {
                order: 3;
              }
              &:nth-child(6) {
                order: 4;
              }
              &:nth-child(7) {
                order: 7;
              }
              &:nth-child(8) {
                order: 6;
              }
              .title {
                padding: 0;
                font-size: 0.26rem;
                margin-bottom: 0.16rem;
              }
              img {
                width: 0.22rem;
              }
              span {
                margin-left: 0.1rem;
                font-size: 0.22rem;
                line-height: 0.26rem;
              }
            }
          }
          .package {
            position: absolute;
            left: 52%;
            margin-left: 0;
            bottom: 0.4rem;
            .title {
              padding: 0;
              font-size: 0.26rem;
            }
            .text {
              font-size: 0.22rem;
              line-height: 0.26rem;
            }
          }
        }
      }
    }
  }
}
</style>
