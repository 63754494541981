<template>
  <div class="blockchain-container">
    <p class="title">{{ $t('blockchain.title') }}</p>
    <div class="keypal" @click.stop="openKeypalBuy(1, true)">
      {{ $t('blockchain.getKeypal') }}
    </div>
    <div class="split"></div>
    <div class="chain-table">
      <div class="title-container">
        <div
          class="chain-title"
          v-for="(item, index) in titleMobileList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="title-container-pc">
        <div class="chain-title">
          <span>{{ titleList[0] }}</span>
        </div>
        <div class="chain-title">
          <img src="../../assets/images/blockchain/keypal.png" />
          <span>{{ titleList[1] }}</span>
        </div>
        <div class="chain-title">
          <img src="../../assets/images/blockchain/tp-new.png" />
          <span>{{ titleList[2] }}</span>
        </div>
        <div class="chain-title">
          <span>{{ titleList[3] }}</span>
        </div>
      </div>
      <div class="content-container">
        <div
          class="chain-row"
          v-for="(item, index) in blockchainList"
          :key="index"
        >
          <div class="chain-name">
            <img class="logo" :src="item.icon" />
            <span class="name">{{ item.name }}</span>
          </div>
          <div class="row-wrap">
            <img
              v-if="item.keypalSupport"
              class="keypal-support"
              :src="
                item.keypalSupport
                  ? require('../../assets/images/blockchain/true.png')
                  : require('../../assets/images/blockchain/false.png')
              "
            />
            <div class="keypal-support-text" :class="{ 'english': $i18n.locale === 'en' }" v-else>{{ $t('blockchain.comingSoon') }}</div>
          </div>
          <div class="row-wrap">
            <img
              class="tp-support"
              :src="
                item.tpSupport
                  ? require('../../assets/images/blockchain/true.png')
                  : require('../../assets/images/blockchain/false.png')
              "
            />
          </div>
          <div class="row-wrap">
            <a :href="item.url">
              <span>{{ $t('blockchain.list.urlName') }}</span>
              <img
                class="right"
                src="../../assets/images/blockchain/right.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <KeypalBuy
      :dialogType="dialogType"
      @setKeypalBuyVisible="setKeypalBuyVisible"
      v-if="keypalBuyVisible"
    />
  </div>
</template>

<script>
import KeypalBuy from '../common/KeypalBuy.vue'
export default {
  name: 'Blockchain',
  components: { KeypalBuy },
  data() {
    return {
      dialogType: 1,
      keypalBuyVisible: false,
    }
  },
  computed: {
    titleMobileList() {
      return [
        'Blockchain',
        'KeyPal/TokenPocket',
        this.$t('blockchain.list.detail'),
      ]
    },
    titleList() {
      return [
        'Blockchain',
        'KeyPal',
        'TokenPocket',
        this.$t('blockchain.list.detail'),
      ]
    },

    blockchainList() {
      return [
        {
          icon: require('../../assets/images/blockchain/btc.png'),
          name: 'BTC',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://bitcoin.org/zh_CN/',
        },
        {
          icon: require('../../assets/images/blockchain/eth.png'),
          name: 'ETH',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://ethereum.org/zh/',
        },
        {
          icon: require('../../assets/images/blockchain/usdt.png'),
          name: 'USDT',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://tether.to/',
        },
        {
          icon: require('../../assets/images/blockchain/bsc.png'),
          name: 'BSC',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.bnbchain.org/cn/smartChain',
        },
        {
          icon: require('../../assets/images/blockchain/tron.png'),
          name: 'TRON',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://tron.network/index?lng=zh',
        },
        {
          icon: require('../../assets/images/blockchain/heco.png'),
          name: 'HECO',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.hecochain.com/tc/',
        },
        {
          icon: require('../../assets/images/blockchain/solana.png'),
          name: 'Solana',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://solana.com/zh',
        },
        {
          icon: require('../../assets/images/blockchain/matic.png'),
          name: 'Polygon (Matic)',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://polygon.technology/',
        },
        {
          icon: require('../../assets/images/blockchain/dot.png'),
          name: 'Polkadot',
          keypalSupport: false,
          tpSupport: true,
          url: 'https://polkadot.network/zh-cn/',
        },
        {
          icon: require('../../assets/images/blockchain/etc.png'),
          name: 'ETC',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://ethereumclassic.org/',
        },
        {
          icon: require('../../assets/images/blockchain/ksm.png'),
          name: 'Kusama',
          keypalSupport: false,
          tpSupport: true,
          url: 'https://kusama.network/',
        },
        {
          icon: require('../../assets/images/blockchain/okex.png'),
          name: 'OKExChain',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.okex.com/cn/okexchain',
        },
        {
          icon: require('../../assets/images/blockchain/eos.png'),
          name: 'EOS',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://eos.io/',
        },
        {
          icon: require('../../assets/images/blockchain/avax.png'),
          name: 'Avalanche',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://cchain.explorer.avax.network/',
        },
        {
          icon: require('../../assets/images/blockchain/kcc.png'),
          name: 'KCC Mainnet',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.kcc.io/?spm=kcWeb.B1homepage.Header1.9',
        },
        {
          icon: require('../../assets/images/blockchain/bttc.png'),
          name: 'BTTC',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.bittorrent.com/zh-cn/',
        },
        {
          icon: require('../../assets/images/blockchain/gt.png'),
          name: 'GT',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.gatechain.io/?lng=zh',
        },
        {
          icon: require('../../assets/images/blockchain/harmony.png'),
          name: 'Harmony',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.harmony.one/',
        },
        {
          icon: require('../../assets/images/blockchain/fantom.png'),
          name: 'Fantom',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://fantom.foundation/',
        },
        {
          icon: require('../../assets/images/blockchain/halo.png'),
          name: 'HALO',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://halo.land/',
        },
        {
          icon: require('../../assets/images/blockchain/arb.png'),
          name: 'Arbitrum',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://arbitrum.io/',
        },
        {
          icon: require('../../assets/images/blockchain/aurura.png'),
          name: 'Aurura',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://aurora.dev/',
        },
        {
          icon: require('../../assets/images/blockchain/op.png'),
          name: 'Optimistic Ethereum',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.optimism.io/',
        },
        {
          icon: require('../../assets/images/blockchain/conflux.png'),
          name: 'Conflux eSpace',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://confluxnetwork.org',
        },
        {
          icon: require('../../assets/images/blockchain/platOn.png'),
          name: 'PlatON',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.platon.network',
        },
        {
          icon: require('../../assets/images/blockchain/moonbeam.png'),
          name: 'Moonbeam',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://moonbeam.network/',
        },
        {
          icon: require('../../assets/images/blockchain/gnosis.png'),
          name: 'Gnosis Chain (xDai)',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://gnosis.io/',
        },
        {
          icon: require('../../assets/images/blockchain/bnb.png'),
          name: 'BNB',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.binance.com/',
        },
        {
          icon: require('../../assets/images/blockchain/Blast.png'),
          name: 'Blast',
          keypalSupport: true,
          tpSupport: true,
          url: this.$i18n.locale === 'zh' ? 'https://blast.io/zh-CN' : 'https://blast.io/en',
        },
        {
          icon: require('../../assets/images/blockchain/X Layer.png'),
          name: 'X Layer',
          keypalSupport: true,
          tpSupport: true,
          url: this.$i18n.locale === 'zh' ? 'https://okx.com/zh-hans/xlayer' : 'https://okx.com/xlayer',
        },
        {
          icon: require('../../assets/images/blockchain/Base.png'),
          name: 'Base',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.base.org/',
        },
        {
          icon: require('../../assets/images/blockchain/Linea.png'),
          name: 'Linea',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://linea.build/',
        },
        {
          icon: require('../../assets/images/blockchain/Scroll.png'),
          name: 'Scroll',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://scroll.io/',
        },
        {
          icon: require('../../assets/images/blockchain//Mantle.png'),
          name: 'Mantle',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://mantle.xyz/',
        },
        {
          icon: require('../../assets/images/blockchain/CORE.png'),
          name: 'CORE',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://coredao.org/',
        },
        {
          icon: require('../../assets/images/blockchain/HAQQ.png'),
          name: 'HAQQ',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://haqq.network/',
        },
        {
          icon: require('../../assets/images/blockchain/ZetaChain.png'),
          name: 'ZetaChain',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.zetachain.com/',
        },
        {
          icon: require('../../assets/images/blockchain/Filecoin FVM.png'),
          name: 'Filecoin FVM',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://filecoin.io/',
        },
        {
          icon: require('../../assets/images/blockchain/Bitlayer.png'),
          name: 'Bitlayer',
          keypalSupport: true,
          tpSupport: true,
          url: this.$i18n.locale === 'zh' ? 'https://bitlayer.org/zh-CN/' : 'https://bitlayer.org/',
        },
        {
          icon: require('../../assets/images/blockchain/Kroma.png'),
          name: 'Kroma',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://kroma.network/',
        },
        {
          icon: require('../../assets/images/blockchain/Metis.png'),
          name: 'Metis',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.metis.io/',
        },
        {
          icon: require('../../assets/images/blockchain/BiHelix(RGB-LN).png'),
          name: 'BiHelix(RGB-LN)',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.bihelix.net/',
        },
        {
          icon: require('../../assets/images/blockchain/Merlin.png'),
          name: 'Merlin',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://merlinchain.io/',
        },
        {
          icon: require('../../assets/images/blockchain/Mint.png'),
          name: 'Mint',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://www.mintchain.io/',
        },
        {
          icon: require('../../assets/images/blockchain/Manta.png'),
          name: 'Manta',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://manta.network/',
        },
        {
          icon: require('../../assets/images/blockchain/ZKFair.png'),
          name: 'ZKFair',
          keypalSupport: true,
          tpSupport: true,
          url: 'https://zkfair.io/',
        },
      ]
    },
  },
  methods: {
    setKeypalBuyVisible(visible, type) {
      // childValue就是子组件传过来的值
      this.keypalBuyVisible = visible
      this.dialogType = type
    },
    openKeypalBuy(type, type1) {
      if (this.$i18n.locale === 'zh') {
        // this.$emit('setKeypalBuyVisible', true, type)
        // this.keypalBuyVisible = true
        window.open('https://j.youzan.com/o5Rh1B')
      } else {
        window.open('https://www.keypal.store/')
        // if (type === 1) {
        //   if (type1 === 'single') {
        //     window.location =
        //       'https://keypalwallet.mystrikingly.com/store/products/keypal-hardware-wallet'
        //   } else {
        //     window.location =
        //       'https://keypalwallet.mystrikingly.com/store/products/keypal-suit'
        //   }
        // } else if (type === 2) {
        //   window.location =
        //     'https://keypalwallet.mystrikingly.com/store/products/keypal-mnemonic-secret-box'
        // } else {
        //   window.location =
        //     'https://www.keypal.store/products/keypal-leather-case'
        // }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .blockchain-container {
    position: relative;
    //z-index: -89;
    .title {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #333333;
      text-align: left;
      line-height: 0.45rem;
      width: 4.38rem;
      margin-left: 0.32rem;
      margin-top: 0.14rem;
      margin: 0;
      padding: 0;
    }
    .subtitle {
      font-size: 0.32rem;
      transform: scale(0.5);
      transform-origin: 0 0;
      word-break: break-all;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #999999;
      line-height: 0.44rem;
      width: 8.5rem;
      margin-left: 0.32rem;
      margin-top: 0.08rem;
    }
    .keypal {
      // width: 4.66rem;
      // height: 1.56rem;
      width: 4.66rem;
      height: 1.56rem;
      line-height: 1.56rem;
      background: #2761e7;
      border-radius: 0.08rem;
      font-size: 0.44rem;
      transform: scale(0.5);
      transform-origin: 0 0;
      font-family: PingFangSC, PingFangSC-Medium;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 0.05rem;
      // left: 4.9rem;
      right: -2.44rem;
    }
    .split {
      margin-top: 0.2rem;
      height: 0.2rem;
      background: #f5f5f5;
    }
    .chain-table {
      // margin-left: 0.33rem;
      .title-container-pc {
        display: none;
      }
      .title-container {
        height: 0.93rem;
        line-height: 0.93rem;
        .chain-title {
          float: left;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: bold;
          color: #333333;
          font-size: 0.24rem;
          &:nth-child(1) {
            width: 2.76rem;
          }
          &:nth-child(2) {
            text-align: center;
            width: 2.76rem;
          }
          &:nth-child(3) {
            text-align: center;
            width: 1.33rem;
          }
        }
      }
      .content-container {
        margin-bottom: 0.62rem;
        .chain-row {
          display: flex;
          align-items: center;
          height: 0.84rem;
          border-bottom: #f0f0f0 0.5px solid;
          &:last-of-type {
            border-bottom: none;
          }
          .chain-name {
            flex: 2;
            display: flex;
            align-items: center;
          }
          .row-wrap {
            flex: 1;
            display: flex;
            justify-content: center;
          }
          .logo {
            height: 0.44rem;
          }
          .name {
            left: 0.58rem;
            font-size: 0.2rem;
          }
          .keypal-support {
            height: 0.32rem;
            left: 3.54rem;
          }
          .keypal-support-text {
            background: rgba(153,153,153,0.08);
            border-radius: 0.1rem;
            padding: 0.08rem 0.12rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC-Medium;
            text-align: center;
            color: #999999;
          }
          .tp-support {
            height: 0.32rem;
            left: 4.42rem;
          }
          a {
            font-size: 0.22rem;
            font-family: PingFangSC, PingFangSC-Regular;
            color: #2761e7;
            line-height: 0.3rem;
            display: inline-block;
            left: 5.89rem;
            img {
              width: 0.06rem;
              margin-left: 0.1rem;
              transform: translateY(-0.02rem);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .blockchain-container {
    position: relative;
    .title {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #333333;
      text-align: left;
      line-height: 0.4rem;
      margin-top: 0.8rem;
    }
    .subtitle {
      font-size: 0.16rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #999999;
      line-height: 0.22rem;
      margin-top: 0.04rem;
    }
    .keypal {
      cursor: pointer;
      width: 2.98rem;
      height: 0.58rem;
      line-height: 0.58rem;
      background: #2761e7;
      border-radius: 0.08rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Medium;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: -0.1rem;
      // left: 7.62rem;
      right: 0;
    }
    .split {
      margin-top: 0.31rem;
      height: 0.21rem;
      background: #f5f5f5;
    }
    .chain-table {
      // margin-left: 0.33rem;
      .title-container {
        display: none;
      }
      .title-container-pc {
        height: 0.96rem;
        line-height: 0.96rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        .chain-title {
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: bold;
          font-size: 0.2rem;
          color: #333333;
          display: flex;
          &:nth-child(1) {
            width: 4.08rem;
            padding-left: 0.1rem;
          }
          &:nth-child(2) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.31rem;
            img {
              width: 0.26rem;
              margin-right: 0.08rem;
            }
          }
          &:nth-child(3) {
            width: 2.31rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-right: 0.06rem;
              width: 0.43rem;
            }
          }
          &:nth-child(4) {
            width: 2.31rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .content-container {
        margin-bottom: 0.94rem;
        .chain-row {
          position: relative;
          height: 0.96rem;
          border-bottom: #f0f0f0 1px solid;
          display: flex;
          align-items: center;
          &:last-of-type {
            border-bottom: none;
          }
          .chain-name {
            width: 4.08rem;
            display: flex;
            align-items: center;
          }
          .row-wrap {
            width: 2.31rem;
            display: flex;
            justify-content: center;
          }
          .logo {
            height: 0.56rem;
          }
          .name {
            margin-left: 0.1rem;
            font-size: 0.18rem;
            font-family: PingFangSC, PingFangSC-Medium;
            color: #333333;
          }
          .keypal-support {
            height: 0.3rem;
            left: 5rem;
            // position: absolute;
          }
          .keypal-support-text {
            background: rgba(153,153,153,0.08);
            border-radius: 0.1rem;
            padding: 0.08rem 0.12rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC-Medium;
            text-align: center;
            color: #999999;
          }
          .tp-support {
            height: 0.3rem;
            left: 7.2rem;
            // position: absolute;
          }
          a {
            // position: absolute;
            font-size: 0.18rem;
            font-family: PingFangSC, PingFangSC-Regular;
            color: #2761e7;
            left: 9.4rem;
            img {
              width: 0.06rem;
              margin-left: 0.1rem;
              transform: translateY(-0.02rem);
            }
          }
        }
      }
    }
  }
}
</style>
