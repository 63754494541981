<template>
  <div class="easy-safe-item">
    <div class="img-wrap">
      <img :src="obj.img" />
      <div
        class="blur"
        :class="{
          'blur-1': obj.id === 0,
          'blur-2': obj.id === 1,
          'blur-3': obj.id === 2,
          'blur-4': obj.id === 3,
          'blur-5': obj.id === 4,
        }"
      ></div>
    </div>
    <div class="text-wrap">
      <div
        class="text"
        :class="{ plus: isKeyPlaPlus, color: obj.id === 3 || obj.id === 4 }"
      >
        {{ obj.text1 }}
      </div>
      <div class="text text-2" :class="{ plus: isKeyPlaPlus }">
        {{ obj.text2 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isKeyPlaPlus: {
      type: Boolean,
    },
    obj: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.easy-safe-item {
  .blur {
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    opacity: 0.83;
    background: #2980fe;
    border-radius: 50%;
    filter: blur(0.5rem);
  }
  .img-wrap {
    font-size: 0;
    position: relative;
    img {
      width: 3rem;
    }
    .blur-1 {
      right: 0.5rem;
      bottom: 0.22rem;
    }
    .blur-2 {
      right: 0.5rem;
      top: 0.67rem;
    }
    .blur-3 {
      left: 0.5rem;
      bottom: 0.31rem;
    }
    .blur-4 {
      left: 0.55rem;
      bottom: 0.32rem;
    }
    .blur-5 {
      left: 0.55rem;
      bottom: 0.32rem;
    }
  }
  .text-wrap {
    margin-top: 0.12rem;
    .text {
      text-align: center;
      font-size: 0.18rem;
      font-family: Medium;
    }
    .text-2 {
      margin-top: 0.12rem;
      font-size: 0.14rem;
      color: #666666;
      line-height: 0.17rem;
      font-family: Light;
    }
    .plus {
      color: #fff;
    }
    .color {
      color: #00fff8;
    }
  }
}
@media screen and (max-width: 767px) {
  .easy-safe-item {
    .text-wrap {
      .text {
        font-size: 0.26rem;
      }
      .text-2 {
        font-size: 0.18rem;
        line-height: 0.24rem;
      }
    }
  }
}
</style>
