<template>
  <div class="security">
    <Header security />
    <div class="content">
      <div class="security-header d-flex ai-center jc-between">
        <div class="security-left">
          <div class="title" :class="{ chinese: $i18n.locale !== 'en' }">
            {{ $t('security.title_1') }}
          </div>
          <div class="subtitle">{{ $t('security.desc_1_1') }}</div>
          <div class="subtitle subtitle-2">{{ $t('security.desc_1_2') }}</div>
          <div class="subtitle subtitle-3">{{ $t('security.desc_1_3') }}</div>
          <Button security />
        </div>
        <div class="security-img-wrap">
          <img
            class="security-img"
            src="../assets/images/security/security-img.png"
          />
          <div class="blur"></div>
        </div>
      </div>
      <div class="secure">
        <div class="title">{{ $t('security.title_2') }}</div>
        <div class="secure-list d-flex jc-between">
          <div
            class="secure-item d-flex flex-column ai-center"
            v-for="(item, index) in secureList"
            :key="index"
          >
            <div class="secure-img">
              <img :src="item.img" />
              <div class="blur" :class="['blur-' + index]"></div>
            </div>
            <div class="text label">{{ item.label }}</div>
            <div class="text desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="why-keypal">
        <div class="title">{{ $t('security.title_3') }}</div>
        <div class="title-2">{{ $t('security.desc_3_1') }}</div>
        <div class="text desc">{{ $t('security.desc_3_2') }}</div>
        <div class="top d-flex jc-between">
          <SecurityItem :sItem="securityItem_1" isHalf />
          <SecurityItem :sItem="securityItem_2" isHalf />
        </div>
        <div class="title desc-3">{{ $t('security.desc_3_3') }}</div>
        <SecurityItem :sItem="securityItem_3" />
        <div class="title desc-3">{{ $t('security.desc_3_5') }}</div>
        <SecurityItem :sItem="securityItem_4" />
        <div class="title desc-3">{{ $t('security.desc_3_7') }}</div>
        <SecurityItem :sItem="securityItem_5" />
        <SecurityItem :sItem="securityItem_6" />
      </div>
    </div>
    <Footer security />
  </div>
</template>

<script>
import Button from '../components/common/Button.vue';
import Footer from '../components/common/Footer.vue';
import Header from '../components/common/Header.vue';
import SecurityItem from '../components/common/SecurityItem.vue';
export default {
  components: { Header, Footer, Button, SecurityItem },
  computed: {
    secureList() {
      return [
        {
          img: require('../assets/images/security/secure-1.png'),
          label: this.$t('security.desc_2_1'),
          desc: this.$t('security.desc_2_2'),
        },
        {
          img: require('../assets/images/security/secure-2.png'),
          label: this.$t('security.desc_2_3'),
          desc: this.$t('security.desc_2_4'),
        },
        {
          img: require('../assets/images/security/secure-3.png'),
          label: this.$t('security.desc_2_5'),
          desc: this.$t('security.desc_2_6'),
        },
      ];
    },
    securityItem_1() {
      return {
        img: require('../assets/images/security/se.png'),
        desc_1: this.$t('security.SE_desc_1'),
        desc_2: this.$t('security.SE_desc_2'),
        desc_3: this.$t('security.SE_desc_3'),
        desc_4: this.$t('security.SE_desc_4'),
      };
    },
    securityItem_2() {
      return {
        img: require('../assets/images/security/mcu.png'),
        desc_1: this.$t('security.MCU_desc_1'),
        desc_2: this.$t('security.MCU_desc_2'),
        desc_3: this.$t('security.MCU_desc_3'),
      };
    },
    securityItem_3() {
      return {
        img: require('../assets/images/security/why-keyapl-1.png'),
        desc_1: this.$t('security.desc_3_4'),
      };
    },
    securityItem_4() {
      return {
        img: require('../assets/images/security/why-keyapl-2.png'),
        desc_1: this.$t('security.desc_3_6'),
      };
    },
    securityItem_5() {
      return {
        img: require('../assets/images/security/why-keyapl-3.png'),
        desc_1: this.$t('security.desc_3_8'),
      };
    },
    securityItem_6() {
      return {
        img: require('../assets/images/security/why-keyapl-4.png'),
        desc_1: this.$t('security.desc_3_9'),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.security {
  background: #101010;
  .blur {
    position: absolute;
    width: 1rem;
    height: 1rem;
    opacity: 0.55;
    background: #19716e;
    border-radius: 50%;
    filter: blur(50px);
  }
  .title {
    color: #00f2ef;
    text-align: left;
  }
  .text {
    font-family: Light;
  }
  .content {
    max-width: 12rem;
    .security-header {
      width: 100%;
      .security-left {
        max-width: 5.3rem;
        .title {
          font-size: 0.56rem;
          line-height: 0.54rem;
          &.chinese {
            line-height: 0.7rem;
          }
        }
        .subtitle {
          text-align: left;
          opacity: 0.7;
          font-size: 0.14rem;
          color: #ffffff;
          line-height: 0.28rem;
          margin-top: 0.22rem;
          font-family: Light;
        }
        .subtitle-2 {
          margin-top: 0.4rem;
          font-family: Light;
        }
        .subtitle-3 {
          opacity: 0.95;
          color: #00f2ef;
          margin-top: 0.42rem;
          font-family: Light;
        }
        .button {
          display: flex;
          margin-top: 0.5rem;
          background: linear-gradient(
            135deg,
            rgba(41, 128, 254, 0.2),
            rgba(41, 128, 254, 0.05) 18%,
            rgba(41, 128, 254, 0.05)
          );
          border: 0.01rem solid rgba(41, 128, 254, 0.5);
          border-radius: 0.39rem;
          .img-wrap {
            background: #183968;
          }
        }
        .button:hover {
          background: #2980fe;
          .img-wrap {
            background: #1a68da;
          }
        }
      }
      .security-img-wrap {
        position: relative;
        font-size: 0;
        margin-right: 0.8rem;
        .security-img {
          width: 5.24rem;
        }
        .blur {
          right: 0.5rem;
          bottom: 1.4rem;
        }
      }
    }
    .secure {
      margin-top: 1.2rem;
      .title {
        font-size: 0.3rem;
        color: #00e6e3;
      }
      .secure-list {
        margin-top: 0.8rem;
        .secure-item {
          .secure-img {
            position: relative;
            img {
              width: 3.28rem;
            }
            .blur-0 {
              right: 0.6rem;
              bottom: 0.6rem;
            }
            .blur-1 {
              left: 0.94rem;
              bottom: 0.48rem;
            }
            .blur-2 {
              left: 0.66rem;
              bottom: 0.76rem;
            }
          }
          .label {
            font-size: 0.24rem;
            text-align: center;
            color: #ffffff;
            font-family: Bold;
          }
          .desc {
            margin-top: 0.12rem;
            font-size: 0.14rem;
            color: #fff;
          }
        }
      }
    }
    .why-keypal {
      margin-top: 1rem;
      margin: 1rem 0 0.43rem;
      .title {
        font-size: 0.32rem;
      }
      .title-2 {
        margin-top: 0.42rem;
        font-size: 0.24rem;
        text-align: left;
        color: #ffffff;
        font-family: Bold;
      }
      .desc {
        margin-top: 0.12rem;
        font-size: 0.16rem;
        color: #ffffff;
        font-family: Light;
      }
      .desc-3 {
        margin-top: 0.72rem;
        font-size: 0.24rem;
        text-align: left;
        color: #ffffff;
        font-family: Bold;
      }
      .security-item {
        margin-top: 0.3rem;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .security {
    .title {
      padding: 0;
    }
    .content {
      .security-header {
        flex-direction: column;
        .security-left {
          order: 10;
          max-width: none;
          margin-top: 0.6rem;
          .title {
            line-height: 0.6rem;
            max-width: 5rem;
          }
          .subtitle {
            margin-top: 0.3rem;
            font-size: 0.24rem;
          }
          .subtitle-2 {
            font-size: 0.22rem;
          }
          .subtitle-3 {
            font-size: 0.26rem;
            line-height: 0.31rem;
          }
          .button {
            margin: 0;
            margin-top: 0.5rem;
            border-radius: 0.5rem;
            .img-wrap {
              background: #183968;
            }
          }
          .button:hover {
            background: #2980fe;
            .img-wrap {
              background: #1a68da;
            }
          }
        }
        .security-img-wrap {
          margin-right: 0;
          .security-img {
            margin-right: 0;
            margin-top: 0.5rem;
            order: 2;
          }
        }
      }
      .secure {
        .title {
          font-size: 0.36rem;
        }
        .secure-list {
          flex-wrap: wrap;
          .secure-item {
            &:nth-child(n + 3) {
              margin-top: 0.4rem;
            }
            .label {
              font-size: 0.26rem;
              font-family: Bold;
            }
            .desc {
              font-size: 0.2rem;
            }
          }
        }
      }
      .why-keypal {
        .title {
          font-size: 0.36rem;
        }
        .title-2 {
          font-size: 0.32rem;
        }
        .desc {
          font-size: 0.24rem;
          font-family: Light;
        }
        .desc-3 {
          font-size: 0.32rem;
        }
        .top {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
