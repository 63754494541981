<template>
  <div class="message-box">
    <span
      class="text"
      :class="{
        'width-200': width200,
        'width-255': width255,
        'width-300': width300,
      }"
      >{{ text }}</span
    >
    <svg
      :class="{ bottom: bottom, batch: batch, english: $i18n.locale === 'zh' }"
      width="10"
      height="10"
      viewBox="-50 -50 300 300"
    >
      <polygon
        class="triangle radius"
        stroke-linejoin="round"
        points="100,0 -80,200 280,200"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    width200: {
      type: Boolean,
    },
    width255: {
      type: Boolean,
    },
    width300: {
      type: Boolean,
    },
    bottom: {
      type: Boolean,
    },
    batch: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.message-box {
  background: #00e6e3;
  border-radius: 0.08rem;
  padding: 0.07rem 0.15rem;
  position: relative;
  white-space: nowrap;
  span {
    display: block;
    font-size: 0.12rem;
    line-height: 0.14rem;
    font-family: Light;
    &.width-200 {
      width: 2.1rem;
      white-space: normal;
    }
    &.width-255 {
      width: 2.55rem;
    }
  }
  svg {
    position: absolute;
    right: -0.05rem;
    top: 0.1rem;
    transform: rotate(90deg);
    &.bottom {
      transform: rotate(180deg);
      left: 0.2rem;
      top: 0.24rem;
    }
    &.batch {
      left: 2.9rem;
      &.english {
        left: 1.9rem;
      }
    }
  }
  .triangle {
    fill: #00e6e3;
    stroke: #00e6e3;
    stroke-width: 5;
  }
  // 可以通过 stroke-width 控制圆角大小
  .radius {
    stroke-width: 100;
  }
}
@media screen and (max-width: 767px) {
  .message-box {
    padding: 0.12rem 0.32rem;
    span {
      font-size: 0.18rem;
      &.width-300 {
        width: 2.5rem;
        white-space: pre-wrap;
        line-height: 0.21rem;
      }
      text-align: center;
    }
    svg {
      right: -0.1rem;
      &.bottom {
        top: auto;
        bottom: -0.1rem;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }
}
</style>
