<template>
  <div class="buy-keypal">
    <div class="content d-flex jc-between">
      <div class="keypal-display d-flex">
        <div class="keypal-small d-flex flex-column jc-between">
          <div
            class="small-item"
            :class="{ active: curImgIndex === index }"
            v-for="(item, index) in currentKeyPaList"
            :key="index"
            @click="onSmall(index)"
          >
            <img :src="item.img" />
          </div>
        </div>
        <div class="keypal-big">
          <div class="big-item">
            <img
              v-for="(item, index) in currentKeyPaList"
              :key="index"
              v-show="curImgIndex === index"
              class="main-img"
              :src="item.img"
            />
          </div>
          <div class="left-right d-flex jc-between">
            <div class="wrap" @click="onPlay(false)">
              <img
                class="left"
                src="../../assets/images/keypal-plus/right-pc.png"
              />
              <img
                class="left-mobile"
                src="../../assets/images/keypal-plus/right-m.png"
              />
            </div>
            <div class="wrap" @click="onPlay(true)">
              <img
                class="right"
                src="../../assets/images/keypal-plus/right-pc.png"
              />
              <img
                class="right-mobile"
                src="../../assets/images/keypal-plus/right-m.png"
              />
            </div>
          </div>
          <div class="point-index d-flex">
            <span class="text">{{ curImgIndex + 1 }}</span>
            <span class="text">/</span>
            <span class="text">{{ currentKeyPaList.length }}</span>
          </div>
        </div>
      </div>
      <div class="keypal-detail">
        <div class="text title name" :class="{ 'keypal-plus': isKeyPalPlus }">
          {{ isKeyPalPlus ? 'KeyPal Plus' : 'KeyPal' }}
        </div>
        <div
          class="text buy-desc buy-desc-2"
          :class="{ 'keypal-plus': isKeyPalPlus }"
        >
          {{ $t('keypal.buy_desc_1') }}
        </div>
        <div class="text buy-desc" :class="{ 'keypal-plus': isKeyPalPlus }">
          {{ $t('keypal.buy_desc_2') }}
        </div>
        <div class="text buy-desc" :class="{ 'keypal-plus': isKeyPalPlus }">
          {{ $t('keypal.buy_desc_3') }}
        </div>
        <div
          class="larger-battery d-flex ai-center jc-center"
          v-if="isKeyPalPlus"
        >
          <img src="../../assets/images/keypal-plus/larger-bettery.png" />
          <span class="text">
            {{ $t('keypal.keypal_plus.larger_battery') }}
          </span>
        </div>
        <div
          class="text title features"
          :class="{ 'keypal-plus': isKeyPalPlus }"
        >
          {{ $t('keypal.features') }}
        </div>
        <div class="features-list d-flex flex-wrap jc-between">
          <div
            class="features-item"
            v-for="(fItem, index) in featuresList"
            :key="index"
          >
            <div class="text title">{{ fItem.title }}</div>
            <div class="text desc">{{ fItem.desc }}</div>
          </div>
        </div>
        <div class="text title color" :class="{ 'keypal-plus': isKeyPalPlus }">
          {{ $t('keypal.color') }}
        </div>
        <div class="color-list d-flex">
          <div
            class="color-item"
            :class="{
              black: color === 'black',
              silver: color === 'silver',
              golden: color === 'golden',
              border: curColor === index,
            }"
            v-for="(color, index) in colorList"
            :key="index"
            @click="onColor(index)"
          ></div>
        </div>
        <div class="buy-wrap d-flex" :class="{ 'keypal-plus': isKeyPalPlus }">
          <Button :isKeyPalPlus="isKeyPalPlus" />
          <div class="free-shipping d-flex ai-center">
            <img src="../../assets/images/keypal-plus/Free-shipping.png" />
            <span class="text">{{ $t('keypal.free_shipping') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue';
export default {
  props: {
    isKeyPalPlus: {
      type: Boolean,
    },
    colorList: {
      type: Array,
    },
  },
  components: { Button },
  data() {
    return {
      blackList: [
        {
          img: require('../../assets/images/keypal-plus/black-0.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/black-1.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/black-2.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/black-3.png'),
        },
      ],
      silverList: [
        {
          img: require('../../assets/images/keypal-plus/silver-0.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/silver-1.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/silver-2.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/silver-3.png'),
        },
      ],
      goldenList: [
        {
          img: require('../../assets/images/keypal-plus/golden-0.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/golden-1.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/golden-2.png'),
        },
        {
          img: require('../../assets/images/keypal-plus/golden-3.png'),
        },
      ],
      curColor: 0,
      curImgIndex: 0,
    };
  },

  computed: {
    currentKeyPaList() {
      if (this.isKeyPalPlus) {
        switch (this.curColor) {
          case 0:
            return this.blackList;
          case 1:
            return this.silverList;
          case 2:
            return this.goldenList;
          default:
            return this.blackList;
        }
      } else {
        return this.blackList;
      }
    },
    featuresList() {
      return [
        {
          title: this.$t('keypal.features_title_1'),
          desc: this.$t('keypal.features_desc_1'),
        },
        {
          title: this.$t('keypal.features_title_2'),
          desc: this.$t('keypal.features_desc_2'),
        },
        {
          title: this.$t('keypal.features_title_3'),
          desc: this.$t('keypal.features_desc_3'),
        },
        {
          title: this.$t('keypal.features_title_4'),
          desc: this.$t('keypal.features_desc_4'),
        },
      ];
    },
  },
  methods: {
    onSmall(index) {
      this.curImgIndex = index;
    },
    onColor(index) {
      this.curColor = index;
    },
    onPlay(flag) {
      if (flag) {
        this.curImgIndex++;
        if (this.curImgIndex === 4) {
          this.curImgIndex = 0;
        }
      } else {
        this.curImgIndex--;
        if (this.curImgIndex === -1) {
          this.curImgIndex = 3;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-keypal {
  background: #fff;
  padding-bottom: 1rem;
  padding-top: 0.3rem;
  .keypal-display {
    .keypal-small {
      .small-item {
        width: 1.2rem;
        height: 1.2rem;
        font-size: 0;
        background: #f6f6f6;
        border: 0.01rem solid #f6f6f6;
        border-radius: 0.02rem;
        &.active {
          border: 0.01rem solid #2980fe;
        }
        img {
          border-radius: 0.02rem;
          width: 100%;
        }
      }
    }
    .keypal-big {
      margin-left: 0.16rem;
      position: relative;
      .big-item {
        width: 5.28rem;
        height: 5.28rem;
        font-size: 0;
        img {
          width: 100%;
        }
      }
      .left-right {
        width: 100%;
        width: 5.16rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.58rem;
          height: 1.58rem;
          background: rgba(255, 255, 255, 0.5);
          border: 0.01rem solid #ffffff;
          border-radius: 0.08rem;
          img {
            width: 0.42rem;
          }
          .left {
            transform: rotate(180deg);
          }
          .right {
            width: 0.42rem;
          }
          .left-mobile {
            display: none;
          }
          .right-mobile {
            display: none;
          }
        }
      }
    }
    .point-index {
      position: absolute;
      right: 0.16rem;
      bottom: 0.16rem;
      opacity: 0.2;
      padding: 0.03rem 0.12rem;
      background: #000000;
      border-radius: 0.12rem;
      span {
        font-size: 0.14rem;
        color: #ffffff;
      }
    }
  }
  .keypal-detail {
    margin-left: 0.36rem;
    flex: 1;
    .name {
      font-size: 0.32rem;
      text-align: left;
      line-height: 0.39rem;
      &.keypal-plus {
        font-size: 0.3rem;
        line-height: normal;
      }
    }
    .buy-desc {
      font-size: 0.14rem;
      margin-top: 0.06rem;
      &.keypal-plus {
        font-size: 0.13rem;
      }
    }
    .buy-desc-2 {
      margin-top: 0.11rem;
    }
    .larger-battery {
      margin-top: 0.12rem;
      width: fit-content;
      padding: 0.04rem 0.12rem;
      background: #ff6236;
      border-radius: 0.04rem;
      img {
        width: 0.11rem;
      }
      span {
        margin-left: 0.08rem;
        font-size: 0.16rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        line-height: 0.22rem;
      }
    }
    .features {
      margin-top: 0.26rem;
      font-size: 0.2rem;
      text-align: left;
      line-height: 0.24rem;
      &.keypal-plus {
        font-size: 0.18rem;
        margin-top: 0.17rem;
        line-height: normal;
      }
    }
    .features-list {
      margin-top: 0.1rem;
      .features-item {
        // width: 2.34rem;
        width: 49%;
        padding: 0.12rem 0.16rem;
        border: 0.01rem solid #c1c1c1;
        border-radius: 0.12rem;
        &:nth-child(n + 3) {
          margin-top: 0.08rem;
        }
        .title {
          font-size: 0.12rem;
          font-weight: 700;
          font-family: 'Bold';
          text-align: left;
          color: #101010;
          line-height: 0.15rem;
        }
        .desc {
          margin-top: 0.06rem;
          font-size: 0.12rem;
          font-weight: 400;
          text-align: left;
          color: #444444;
          line-height: 0.15rem;
        }
      }
    }
    .color {
      margin-top: 0.23rem;
      font-size: 0.2rem;
      text-align: left;
      line-height: 0.24rem;
      &.keypal-plus {
        font-size: 0.18rem;
        margin-top: 0.2rem;
        line-height: normal;
      }
    }
    .color-list {
      margin-top: 0.1rem;
      .color-item {
        width: 0.42rem;
        height: 0.42rem;
        background: linear-gradient(180deg, #2c2c2c 0%, #000000);
        border-radius: 0.16rem;
        box-sizing: border-box;
        cursor: pointer;
        &:nth-child(n + 2) {
          margin-left: 0.1rem;
        }
        &.black {
          background: linear-gradient(180deg, #2c2c2c 0%, #000000);
        }
        &.silver {
          background: linear-gradient(180deg, #f6f6f6, #b9b9b9);
        }
        &.golden {
          background: linear-gradient(180deg, #ffe0b7 0%, #d6ac75);
        }
        &.border {
          border: 0.02rem solid #2980fe;
        }
      }
    }
    .buy-wrap {
      margin-top: 0.3rem;
      &.keypal-plus {
        margin-top: 0.17rem;
      }
      .button {
        display: flex;
        margin: 0;
      }
      .free-shipping {
        margin-left: 0.64rem;
        img {
          width: 0.24rem;
        }
        span {
          margin-left: 0.06rem;
          font-size: 0.16rem;
          text-align: left;
          color: #101010;
          line-height: 0.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .buy-keypal {
    .content {
      flex-direction: column;
      align-items: center;
    }
    .keypal-detail {
      margin-left: 0;
      margin-top: 0.5rem;
      .features-list {
        .features-item {
          width: 2.34rem;
          &:nth-child(n + 3) {
            margin-top: 0;
          }
          &:nth-child(n + 2) {
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .buy-keypal {
    background: #f6f6f6;
    padding-bottom: 0.8rem;
    .content {
      flex-direction: column;
      padding: 0;
    }
    .keypal-display {
      .keypal-small {
        display: none;
      }
      .keypal-big {
        margin-left: 0;
        .big-item {
          width: 100%;
          height: auto;
        }
        .left-right {
          width: 100%;
          padding: 0 0.52rem;
          .wrap {
            .left {
              display: none;
            }
            .right {
              display: none;
            }
            .left-mobile {
              transform: rotate(180deg);
              display: block;
            }
            .right-mobile {
              display: block;
            }
          }
        }
      }
      .point-index {
        right: 0.52rem;
        padding: 0.08rem 0.21rem;
        opacity: 0.2;
        border-radius: 0.22rem;
      }
    }
    .keypal-detail {
      margin-top: 0.5rem;
      margin-left: 0;
      padding: 0 0.32rem;
      display: flex;
      flex-direction: column;
      .title {
        padding: 0;
      }
      .name {
        font-size: 0.44rem;
      }
      .buy-desc {
        margin-top: 0.16rem;
        font-size: 0.24rem;
        &.keypal-plus {
          font-size: 0.24rem;
        }
      }
      .buy-desc-2 {
        margin-top: 0.2rem;
      }
      .features {
        margin-top: 0.4rem;
        font-size: 0.28rem;
        order: 1;
        &.keypal-plus {
          margin-top: 0.4rem;
          font-size: 0.28rem;
        }
      }
      .features-list {
        margin-top: 0.2rem;
        order: 2;
        .features-item {
          width: 48%;
          border-radius: 0.17rem;
          &:nth-child(n + 3) {
            margin-top: 0.11rem;
          }
          &:nth-child(n + 2) {
            margin-left: 0;
          }
          .title {
            font-size: 0.24rem;
            line-height: normal;
          }
          .desc {
            margin-top: 0.08rem;
            font-size: 0.18rem;
            line-height: normal;
          }
        }
      }
      .larger-battery {
        margin-top: 0.32rem;
        padding: 0.08rem 0.24rem;
        border-radius: 0.08rem;
        img {
          width: 0.2rem;
        }
        span {
          font-size: 0.28rem;
        }
      }
      .color {
        margin-top: 0.4rem;
        font-size: 0.28rem;
        &.keypal-plus {
          margin-top: 0.4rem;
          font-size: 0.28rem;
        }
      }
      .color-list {
        margin-top: 0.2rem;
        .color-item {
          width: 0.78rem;
          height: 0.78rem;
          border-radius: 0.3rem;
        }
      }
      .buy-wrap {
        margin-top: 0.8rem;
        order: 3;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        &.keypal-plus {
          margin-top: 0.8rem;
        }
        .free-shipping {
          margin-left: 0;
          margin-top: 0.4rem;
          img {
            width: 0.38rem;
          }
          span {
            font-size: 0.26rem;
          }
        }
      }
    }
  }
}
</style>
