<template>
  <div class="transition-container">
    <img
      class="transition-img forward"
      src="../../assets/images/keypal-plus/forward.png"
    />
    <img
      class="transition-img middle"
      src="../../assets/images/keypal-plus/middle.png"
    />
    <img
      class="transition-img after"
      src="../../assets/images/keypal-plus/after.png"
    />
    <img class="chip" src="../../assets/images/keypal-plus/chip.png" />
    <img
      class="battery-2"
      :class="{ 'keypal-plus': isKeyPalPlus }"
      src="../../assets/images/keypal-plus/battery-2.png"
    />
    <div class="common-wrap desc-list-wrap">
      <div class="desc-list" :class="{ 'plus-bg-color': isKeyPalPlus }">
        <div class="desc-item" v-for="(desc, index) in descList" :key="index">
          <div class="text label" :class="{ plus: isKeyPalPlus }">
            {{ desc.label }}
          </div>
          <div
            class="text label-2"
            :class="{ plus: isKeyPalPlus, english: $i18n.locale === 'en' }"
          >
            {{ desc.text }}
          </div>
        </div>
      </div>
      <img :src="line1" />
    </div>
    <div class="common-wrap display-wrap">
      <div class="common display" :class="{ 'plus-bg-color': isKeyPalPlus }">
        <div class="text label" :class="{ plus: isKeyPalPlus }">
          {{ $t('keypal.display_title') }}
        </div>
        <div class="text label-2" :class="{ plus: isKeyPalPlus }">
          {{ $t('keypal.display_desc') }}
        </div>
      </div>
      <img :src="line2" />
    </div>
    <div
      class="common-wrap battery-wrap"
      :class="{ 'plus-bg-color': isKeyPalPlus }"
    >
      <div class="battery common" :class="{ 'plus-bg-color': isKeyPalPlus }">
        <div class="text label" :class="{ plus: isKeyPalPlus }">
          {{ $t('keypal.battery_title') }}
        </div>
        <div class="text label-2" :class="{ plus: isKeyPalPlus }">
          {{
            isKeyPalPlus
              ? $t('keypal.keypal_plus.battery_desc')
              : $t('keypal.battery_desc')
          }}
        </div>
      </div>
      <img :src="line3" />
    </div>
    <div
      class="common-wrap materials-wrap"
      :class="{ 'plus-bg-color': isKeyPalPlus }"
    >
      <img :src="line4" />
      <div class="materials common" :class="{ 'plus-bg-color': isKeyPalPlus }">
        <div class="text label" :class="{ plus: isKeyPalPlus }">
          {{ $t('keypal.materials_title') }}
        </div>
        <div class="text label-2" :class="{ plus: isKeyPalPlus }">
          {{ $t('keypal.materials_desc') }}
        </div>
      </div>
    </div>
    <div class="common-wrap dual-chip-wrap">
      <img :src="line5" />
      <div class="dual-chip common" :class="{ 'plus-bg-color': isKeyPalPlus }">
        <div class="text label" :class="{ plus: isKeyPalPlus }">
          {{ $t('keypal.dual_chip_title') }}
        </div>
        <div class="text label-2" :class="{ plus: isKeyPalPlus }">
          {{ $t('keypal.dual_chip_desc') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isKeyPalPlus: {
      type: Boolean,
    },
    descList: {
      type: Array,
    },
  },
  computed: {
    line1() {
      if (this.isKeyPalPlus) {
        return require('../../assets/images/keypal-plus/line-green-1.png');
      } else {
        return require('../../assets/images/keypal-plus/line-1.png');
      }
    },
    line2() {
      if (this.isKeyPalPlus) {
        return require('../../assets/images/keypal-plus/line-green-2.png');
      } else {
        return require('../../assets/images/keypal-plus/line-2.png');
      }
    },
    line3() {
      if (this.isKeyPalPlus) {
        return require('../../assets/images/keypal-plus/line-green-3.png');
      } else {
        return require('../../assets/images/keypal-plus/line-3.png');
      }
    },
    line4() {
      if (this.isKeyPalPlus) {
        return require('../../assets/images/keypal-plus/line-green-4.png');
      } else {
        return require('../../assets/images/keypal-plus/line-4.png');
      }
    },
    line5() {
      if (this.isKeyPalPlus) {
        return require('../../assets/images/keypal-plus/line-green-5.png');
      } else {
        return require('../../assets/images/keypal-plus/line-5.png');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.transition-container {
  width: 5rem;
  height: 5.5rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  position: relative;
  .transition-img {
    position: absolute;
    width: 3.5rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 1s;
  }
  .forward {
    z-index: 10;
  }
  .middle {
    z-index: 9;
  }
  .after {
    z-index: 8;
  }
  .chip {
    width: 0.97rem;
    position: absolute;
    top: 1.5rem;
    left: 3.7rem;
    z-index: 20;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .battery-2 {
    width: 0.9rem;
    position: absolute;
    top: 0.55rem;
    left: 3.78rem;
    z-index: 20;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    &.keypal-plus {
      width: 1.2rem;
      top: 0.42rem;
      left: 3.55rem;
    }
  }
  .plus {
    color: #fff;
  }
  .label {
    font-size: 0.14rem;
    font-weight: 500;
    line-height: 0.15rem;
  }
  .label-2 {
    margin-top: 0.05rem;
    font-size: 0.12rem;
    font-weight: 400;
    line-height: 0.18rem;
    &.english {
      line-height: 0.12rem;
    }
  }
  .common-wrap {
    position: absolute;
    display: flex;
    z-index: 50;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    .common {
      height: fit-content;
      padding: 0.08rem 0.22rem;
      background: rgba(255, 255, 255, 0.4);
      border: 0.01rem solid #2980fe;
      border-radius: 0.12rem;
      &.plus-bg-color {
        background: rgba(41, 128, 254, 0.09);
      }
    }
  }
  .desc-list-wrap {
    position: absolute;
    left: -3.5rem;
    bottom: 0;
    display: flex;
    z-index: 50;
    .desc-list {
      width: 1.2rem;
      padding: 0.32rem 0.14rem;
      background: rgba(255, 255, 255, 0.4);
      border: 0.01rem solid #2980fe;
      border-radius: 0.12rem;
      &.plus-bg-color {
        background: rgba(41, 128, 254, 0.09);
      }
      .desc-item {
        &:nth-child(n + 2) {
          margin-top: 0.3rem;
        }
      }
    }
    img {
      width: 2.33rem;
      height: 0.69rem;
      transform: translateY(2.7rem);
    }
  }
  .display-wrap {
    top: -0.2rem;
    left: 0;
    img {
      width: 0.99rem;
      transform: translateY(0.25rem);
    }
  }
  .battery-wrap {
    top: -0.2rem;
    left: 3.4rem;
    img {
      width: 0.38rem;
      transform: translateY(0.25rem);
    }
  }
  .materials-wrap {
    top: -0.2rem;
    left: 5rem;
    white-space: nowrap;
    img {
      width: 0.99rem;
      transform: translateY(0.25rem);
    }
  }
  .dual-chip-wrap {
    top: 2.9rem;
    left: 4.2rem;
    white-space: nowrap;
    img {
      width: 2.4rem;
      transform: translateY(-0.75rem);
    }
  }
}
.transition-container:hover {
  .forward {
    transform: translate(-129%, -51%) rotate(-30deg);
  }
  .middle {
    transform: translate(-50%, -50%) rotate(-15deg);
  }
  .after {
    transform: translate(25%, -50%) rotate(15deg);
  }
  .chip {
    opacity: 1;
  }
  .battery-2 {
    opacity: 1;
  }
  .common-wrap {
    opacity: 1;
  }
}
@media screen and (max-width: 767px) {
  .transition-container {
    .common-wrap {
      display: none;
    }
    .transition-img {
      width: 2.8rem;
    }
    .battery-2 {
      width: 0.91rem;
      top: 0.6rem;
      left: 3.5rem;
    }
    .chip {
      width: 0.9rem;
      left: 3.4rem;
    }
  }
  .transition-container:hover {
    .forward {
      transform: translate(-120%, -50%) rotate(-30deg);
    }
    .middle {
      transform: translate(-50%, -60%) rotate(-15deg);
    }
    .after {
      transform: translate(20%, -50%) rotate(15deg);
    }
  }
}
</style>
