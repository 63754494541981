<template>
  <div class="firmware-upgrade-container">
    <div class="main">
      <div class="main-left">
        <div class="text title">{{ $t('firmwareUpgrade.title') }}</div>
        <div class="text desc">{{ $t('firmwareUpgrade.desc') }}</div>
        <div class="text download">
          {{ $t('firmwareUpgrade.download') }}
        </div>
        <div class="download-button-content">
          <DownLoadButton
            class="button"
            v-for="(item, index) in buttonList"
            :key="index"
            :button="item"
            @onButton="onButton"
          />
          <div class="message" v-if="isShowMsg">
            {{ $t('firmwareUpgrade.goDesktop') }}
          </div>
        </div>
        <a href="https://connect.keypal.pro/" target="_blank">
          <div class="online">
            <span class="text">{{ $t('firmwareUpgrade.onLine') }}</span>
            <div class="online-arrow"></div>
          </div>
        </a>
      </div>
      <div class="main-right">
        <img
          class="keypal"
          src="../../assets/images/firmwareupgrade/keypal-main.png"
        />
        <img
          class="square"
          v-for="(item, index) in imgList"
          :key="index"
          :src="item.url"
          :class="[item.class]"
        />
      </div>
    </div>
    <div class="footer">
      <a
        class="box"
        v-for="(box, index) in boxList"
        :key="index"
        :href="box.url"
        target="_blank"
      >
        <div class="left">
          <img :src="box.icon" />
          <div class="text question">
            {{ box.question }}
          </div>
        </div>
        <div class="right"></div>
      </a>
    </div>
  </div>
</template>

<script>
import DownLoadButton from '../firmwareUpgrade/DownLoadButton.vue'

export default {
  name: 'FirmwareUpgrade',
  components: {
    DownLoadButton,
  },

  computed: {
    buttonList() {
      return [
        {
          class: 'icon-1',
          platform: 'Mac',
          children: [
            {
              text: this.$t('firmwareUpgrade.macTitle'),
              url: 'https://wallet.keypal.pro/data/package/Keypal-Suite-mac-arm64.zip',
            },
            {
              text: 'Mac(Intel)',
              url: 'https://wallet.keypal.pro/data/package/Keypal-Suite-mac-x64.zip',
            },
          ],
        },
        {
          class: 'icon-2',
          platform: 'Windows',
          url: 'https://wallet.keypal.pro/data/package/Keypal-Suite-win-x64.zip',
        },
        {
          class: 'icon-3',
          platform: 'Linux',
          children: [
            {
              text: 'Linux(x86_64)',
              url: 'https://wallet.keypal.pro/data/package/Keypal-Suite-linux-x86_64.zip',
            },
            {
              text: 'Linux(arm_64)',
              url: 'https://wallet.keypal.pro/data/package/Keypal-Suite-linux-arm64.zip',
            },
          ],
        },
      ]
    },
    boxList() {
      return [
        {
          icon: require('../../assets/images/firmwareupgrade/question-icon-1.png'),
          question: this.$t('firmwareUpgrade.question_1'),
          url:
            // 'https://keypal.gitbook.io/zh-cn/keypal-tutorial/6.Firmware-upgrade/6.1Win-PC',
            'https://keypal.gitbook.io/zh-cn/keypal-tutorial/6.Firmware-upgrade/6.2Win-Web',
        },
        {
          icon: require('../../assets/images/firmwareupgrade/question-icon-2.png'),
          question: this.$t('firmwareUpgrade.question_2'),
          url: 'https://keypal.gitbook.io/zh-cn/keypal-tutorial/6.Firmware-upgrade/6.2Win-Web',
        },
      ]
    },
  },
  data() {
    return {
      imgList: [
        {
          class: 'square-1',
          url: require('../../assets/images/firmwareupgrade/square1.png'),
        },
        {
          class: 'square-2',
          url: require('../../assets/images/firmwareupgrade/square1.png'),
        },
        {
          class: 'square-3',
          url: require('../../assets/images/firmwareupgrade/square2.png'),
        },
      ],
      isShowMsg: false,
    }
  },
  beforeDestroy() {
    clearTimeout(this.timerId)
  },
  methods: {
    onButton() {
      this.isShowMsg = true
      this.timerId = setTimeout(() => {
        this.isShowMsg = false
        clearTimeout(this.timerId)
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.firmware-upgrade-container {
  .text {
    font-size: 0.18rem;
    font-family: PingFangSC, PingFangSC-Regular;

    font-weight: 400;
    color: #333;
  }
  .main {
    display: flex;
    justify-content: space-between;
    &-left {
      .title {
        margin-top: 0.7rem;
        font-size: 0.6rem;
        font-family: AlibabaPuHuiTiB, AlibabaPuHuiTiB-Regular;
        font-weight: 700;
      }
      .desc {
        margin-top: 0.2rem;
        font-size: 0.28rem;
        color: #737373;
      }
      .download {
        margin-top: 0.4rem;
        font-size: 0.22rem;
      }
      .download-button-content {
        display: flex;
      }
      .online {
        margin-top: 0.7rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        .online-arrow {
          margin-left: 0.1rem;
          width: 0.07rem;
          height: 0.12rem;
          background: url('../../assets/images/firmwareupgrade/online-arrow.png')
            no-repeat;
          background-size: contain;
        }
      }
      .online:hover {
        span {
          color: #2761e7;
        }
        .online-arrow {
          background: url('../../assets/images/firmwareupgrade/online-arrow-active.png')
            no-repeat;
          background-size: contain;
        }
      }
    }
    &-right {
      position: relative;
      .keypal {
        width: 6.1rem;
        height: 5.14rem;
      }
      .square {
        position: absolute;
      }
      .square-1 {
        width: 1.6rem;
        height: 1.63rem;
        top: -0.2rem;
        left: -0.3rem;
      }
      .square-2 {
        width: 1.6rem;
        height: 1.63rem;
        left: -0.6rem;
        bottom: -1rem;
      }
      .square-3 {
        width: 5.38rem;
        height: 3.6rem;
        right: -1.5rem;
        bottom: -2.2rem;
      }
    }
  }
  .footer {
    height: 3.55rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../assets/images/firmwareupgrade/bottom-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .box {
      width: 5.27rem;
      height: 1.48rem;
      background: #ffffff;
      border: 0.01rem solid #fff;
      border-radius: 0.12rem;
      box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.11);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.3rem;
      cursor: pointer;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 0.88rem;
          height: 0.88rem;
        }
        .question {
          width: 3rem;
          margin-left: 0.2rem;
        }
      }
      .right {
        width: 0.12rem;
        height: 0.24rem;
        background: url('../../assets/images/firmwareupgrade/coming.png')
          no-repeat;
        background-size: contain;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .firmware-upgrade-container {
    .text {
      text-align: left;
    }
    .main {
      max-width: 12rem;
      margin: 0 auto;
      &-left {
        .download-button-content {
          .button {
            &:nth-of-type(n + 2) {
              margin-left: 0.1rem;
            }
          }
        }
      }
    }
    .footer {
      margin-top: 1rem;
      .box:hover {
        border: 0.01rem solid #afc7ff;
      }
      :nth-of-type(n + 2) {
        margin-left: 0.5rem;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .text {
    text-align: center;
  }
  .firmware-upgrade-container {
    .main {
      display: flex;
      flex-direction: column;
      &-left {
        .title {
          font-size: 0.75rem;
        }
        .desc {
          font-size: 0.5rem;
        }
        .download {
          display: none;
        }
        .download-button-content {
          position: relative;
          margin-top: 0.3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .button {
            &:nth-of-type(n + 2) {
              margin-top: 0.1rem;
            }
          }
          .message {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 5rem;
            height: 1.4rem;
            line-height: 1.4rem;
            opacity: 0.8;
            background: #000000;
            border-radius: 0.12rem;
            font-size: 0.26rem;
            text-align: center;
            color: #fff;
          }
        }
        .online {
          display: none;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        .keypal {
          width: 7.5rem;
          height: auto;
        }
        .square {
          display: none;
        }
      }
    }
    .footer {
      height: 5.5rem;
      flex-direction: column;
      .box {
        .left {
          .question {
            width: 3.5rem;
            text-align: left;
            font-size: 0.24rem;
          }
        }
        .right {
          margin-top: 0;
        }
      }
      :nth-of-type(n + 2) {
        margin-top: 0.3rem;
      }
    }
  }
}
</style>
