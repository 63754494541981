<template>
  <div class="keypal-plus">
    <Header isKeyPal isKeyPalDetail />
    <BuyKeyPal isKeyPalPlus :colorList="colorList" />
    <div class="content break-down">
      <img
        class="light"
        src="../assets/images/keypal-plus/keypal-plus-light.png"
      />
      <img
        class="light-m"
        src="../assets/images/keypal-plus/keypal-plus-light-m.png"
      />
      <div class="title main-title">KeyPal Plus</div>
      <TransitionContainer isKeyPalPlus :descList="descList" />
      <div class="easy-and-safe">
        <div class="title-wrap">
          <div class="title">{{ $t('keypal.easy_and_safe_title') }}</div>
          <div class="text desc">
            {{ $t('keypal.easy_and_safe_desc') }}
          </div>
        </div>
        <div class="list d-flex flex-wrap jc-center">
          <EasySafeItem
            v-for="(item, index) in easySafeList"
            :key="index"
            :obj="item"
            isKeyPlaPlus
          />
        </div>
      </div>
      <div class="easy-and-safe product-specifications">
        <div class="title-wrap">
          <div class="title">{{ $t('keypal.product_specifications') }}</div>
        </div>
        <div class="container d-flex">
          <div class="left-wrap">
            <img
              class="keypal-plus-line"
              src="../assets/images/keypal-plus/keypal-plus-line.png"
            />
            <div class="pc">
              <div class="text left-2">{{ $t('keypal.keypal_plus.left') }}</div>
              <div class="text left-3">{{ $t('keypal.keypal_plus.down') }}</div>
              <div class="text right-1">{{ $t('keypal.keypal_plus.up') }}</div>
              <div class="text left-2 right-2">
                {{ $t('keypal.keypal_plus.right') }}
              </div>
              <div class="text left-3 right-3">
                {{ $t('keypal.keypal_plus.ok') }}
              </div>
              <div class="text center">
                {{ $t('keypal.keypal_plus.center') }}
              </div>
            </div>
            <div class="mobile">
              <div
                class="left-2-wrap"
                :class="{ english: $i18n.locale === 'en' }"
              >
                <div class="text text-title left-3">
                  {{ $t('keypal.keypal_plus.left_1') }}
                </div>
                <div class="text left-4">
                  {{ $t('keypal.keypal_plus.left_2') }}
                </div>
              </div>
              <div
                class="left-3-wrap"
                :class="{ english: $i18n.locale === 'en' }"
              >
                <div class="text text-title left-5">
                  {{ $t('keypal.keypal_plus.left_3') }}
                </div>
                <div class="text left-6">
                  {{ $t('keypal.keypal_plus.left_4') }}
                </div>
              </div>
              <div
                class="right-1-wrap"
                :class="{ english: $i18n.locale === 'en' }"
              >
                <div class="text text-title right-1">
                  {{ $t('keypal.keypal_plus.right_1') }}
                </div>
                <div class="text right-2">
                  {{ $t('keypal.keypal_plus.right_2') }}
                </div>
              </div>
              <div
                class="left-2-wrap right-2-wrap"
                :class="{ english: $i18n.locale === 'en' }"
              >
                <div class="text text-title right-3">
                  {{ $t('keypal.keypal_plus.right_3') }}
                </div>
                <div class="text right-4">
                  {{ $t('keypal.keypal_plus.right_4') }}
                </div>
              </div>
              <div
                class="left-3-wrap right-3-wrap"
                :class="{ english: $i18n.locale === 'en' }"
              >
                <div class="text text-title right-5">
                  {{ $t('keypal.keypal_plus.right_5') }}
                </div>
                <div class="text right-6">
                  {{ $t('keypal.keypal_plus.right_6') }}
                </div>
              </div>
              <div class="center-wrap">
                <div class="text center">
                  {{ $t('keypal.keypal_plus.mobile_center') }}
                </div>
                <div class="text center-2">
                  {{ $t('keypal.keypal_plus.mobile_center_2') }}
                </div>
              </div>
            </div>
          </div>
          <div class="specification-wrap">
            <div class="specification-list d-flex flex-wrap jc-between">
              <div
                class="specification-item"
                v-for="(item, index) in specificationList"
                :key="index"
              >
                <div class="text title">{{ item.title }}</div>
                <div class="d-flex ai-center">
                  <img v-if="item.img" :src="item.img" />
                  <span class="text" :class="{ 'no-img': !item.img }">{{
                    item.desc
                  }}</span>
                </div>
              </div>
            </div>
            <div class="specification-list specification-list-2">
              <div class="left">
                <div
                  class="specification-item"
                  v-for="(item, index) in specificationList2"
                  :key="index"
                >
                  <div class="text title">{{ item.title }}</div>
                  <div class="d-flex ai-center">
                    <img v-if="item.img" :src="item.img" />
                    <span class="text" :class="{ 'no-img': !item.img }">{{
                      item.desc
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="package">
                  <div class="title">{{ $t('keypal.package') }}</div>
                  <div class="text">{{ $t('keypal.package_line_1') }}</div>
                  <div class="text">{{ $t('keypal.package_line_2') }}</div>
                  <div class="text">{{ $t('keypal.package_line_3') }}</div>
                  <div class="text">{{ $t('keypal.package_line_4') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer security />
  </div>
</template>

<script>
import BuyKeyPal from '../components/common/BuyKeyPal.vue';
import EasySafeItem from '../components/common/EasySafeItem.vue';
import Footer from '../components/common/Footer.vue';
import Header from '../components/common/Header.vue';
import OperationKeyPal from '../components/common/OperationKeyPal.vue';
import TransitionContainer from '../components/common/TransitionContainer.vue';
export default {
  components: {
    Header,
    Footer,
    BuyKeyPal,
    OperationKeyPal,
    EasySafeItem,
    TransitionContainer,
  },
  data() {
    return {
      colorList: ['black', 'silver', 'golden'],
    };
  },
  computed: {
    descList() {
      return [
        {
          label: this.$t('keypal.break_down_desc_1'),
          text: this.$t('keypal.break_down_desc_2'),
        },
        {
          label: this.$t('keypal.break_down_desc_3'),
          text: this.$t('keypal.break_down_desc_4'),
        },
        {
          label: this.$t('keypal.break_down_desc_5'),
          text: this.$t('keypal.break_down_desc_6'),
        },
        {
          label: this.$t('keypal.break_down_desc_7'),
          text: this.$t('keypal.break_down_desc_8'),
        },
        {
          label: this.$t('keypal.break_down_desc_9'),
          text: this.$t('keypal.break_down_desc_10'),
        },
      ];
    },
    easySafeList() {
      return [
        {
          id: 0,
          img: require('../assets/images/keypal-plus/Dual-chip.png'),
          text1: this.$t('keypal.easy_and_safe_list_1'),
          text2: this.$t('keypal.easy_and_safe_list_2'),
        },
        {
          id: 1,
          img: require('../assets/images/keypal-plus/Easy-connection.png'),
          text1: this.$t('keypal.easy_and_safe_list_3'),
          text2: this.$t('keypal.easy_and_safe_list_4'),
        },
        {
          id: 2,
          img: require('../assets/images/keypal-plus/inch-display.png'),
          text1: this.$t('keypal.easy_and_safe_list_5'),
          text2: this.$t('keypal.easy_and_safe_list_6'),
        },
        {
          id: 3,
          img: require('../assets/images/keypal-plus/Color.png'),
          text1: this.$t('keypal.keypal_plus.color'),
          text2: this.$t('keypal.keypal_plus.color_desc'),
        },
        {
          id: 4,
          img: require('../assets/images/keypal-plus/Battery.png'),
          text1: this.$t('keypal.keypal_plus.battery'),
          text2: this.$t('keypal.keypal_plus.battery_desc'),
        },
      ];
    },
    specificationList() {
      return [
        {
          img: require('../assets/images/specification/card-3-1.png'),
          title: this.$t('keypal.keypal_plus.keypal_card_1'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_1'),
        },
        {
          title: this.$t('keypal.keypal_plus.keypal_card_4'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_4'),
        },
        {
          img: require('../assets/images/specification/card-3-2.png'),
          title: this.$t('keypal.keypal_plus.keypal_card_5'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_5'),
        },
        {
          img: require('../assets/images/specification/card-4-1.png'),
          title: this.$t('keypal.keypal_plus.keypal_card_6'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_6'),
        },
        {
          img: require('../assets/images/specification/card-2-1.png'),
          title: this.$t('keypal.keypal_plus.keypal_card_2'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_2'),
        },
        {
          img: require('../assets/images/specification/card-1-2.png'),
          title: this.$t('keypal.keypal_plus.keypal_card_8'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_8'),
        },
      ];
    },
    specificationList2() {
      return [
        {
          img: require('../assets/images/specification/card-1-1.png'),
          title: this.$t('keypal.keypal_plus.keypal_card_7'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_7'),
        },
        {
          img: require('../assets/images/specification/card-2-2.png'),
          title: this.$t('keypal.keypal_plus.keypal_card_3'),
          desc: this.$t('keypal.keypal_plus.keypal_card_label_3'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.keypal-plus {
  background: #101010;
  .break-down {
    padding: 1rem 0;
    position: relative;
    .light {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
      pointer-events: none;
    }
    .light-m {
      display: none;
    }
    .main-title {
      display: none;
    }
    .easy-and-safe {
      margin-top: 1rem;
      .title-wrap {
        max-width: 7.26rem;
        margin: 0 auto;
        border-radius: 0.08rem;
        .title {
          font-size: 0.32rem;
          line-height: 0.24rem;
          color: #fff;
        }
        .desc {
          margin-top: 0.23rem;
          font-size: 0.22rem;
          text-align: center;
          color: #444444;
          line-height: 0.17rem;
          color: #fff;
        }
      }
      .list {
        margin-top: 0.84rem;
        .easy-safe-item:not(:nth-child(3n + 1)) {
          margin-left: 1.1rem;
        }
        .easy-safe-item:nth-child(n + 4) {
          margin-top: 0.75rem;
        }
      }
    }
    .product-specifications {
      margin-top: 1.3rem;
      .container {
        margin-top: 0.85rem;
        .left-wrap {
          position: relative;
          font-size: 0;
          .keypal-plus-line {
            max-width: 5.04rem;
          }
          .pc {
            .text {
              font-size: 0.14rem;
              font-family: Bold;
              text-align: left;
              color: #00fff8;
              position: absolute;
            }
            .left-2 {
              top: 2.5rem;
              left: 0;
            }
            .left-3 {
              top: 3.5rem;
              left: 0;
            }
            .right-1 {
              top: 1.5rem;
              right: 0;
            }
            .right-2 {
              left: auto;
              right: 0;
            }
            .right-3 {
              left: auto;
              right: 0;
            }
            .center {
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .mobile {
            display: none;
          }
        }
        .specification-wrap {
          margin-left: 0.65rem;
          .specification-list {
            .specification-item {
              width: 49%;
              background: rgba(41, 128, 254, 0.05);
              border: 0.01rem solid rgba(41, 128, 254, 0.5);
              border-radius: 0.12rem;
              padding: 0.2rem 0.35rem;
              &:nth-child(n + 3) {
                margin-top: 0.12rem;
              }

              .title {
                font-size: 0.14rem;
                text-align: left;
                line-height: 0.17rem;
                margin-bottom: 0.17rem;
                color: #fff;
              }
              img {
                width: 0.14rem;
              }
              span {
                margin-left: 0.06rem;
                font-size: 0.12rem;
                text-align: left;
                line-height: 0.15rem;
                color: #fff;
                &.no-img {
                  margin-left: 0;
                }
              }
            }
          }
          .specification-list-2 {
            display: flex;
            margin-top: 0.12rem;
            .left {
              flex: 1;
            }
            .specification-item {
              width: 100%;
              &:nth-child(n + 2) {
                margin-top: 0.12rem;
              }
            }
            .right {
              flex: 1;
              margin-left: 2%;
            }
            .package {
              background: rgba(41, 128, 254, 0.05);
              border: 0.01rem solid rgba(41, 128, 254, 0.5);
              border-radius: 0.12rem;
              padding: 0.2rem 0.35rem;
              .title {
                font-size: 0.14rem;
                font-weight: 700;
                text-align: left;
                line-height: 0.17rem;
                margin-bottom: 0.16rem;
                color: #fff;
              }
              .text {
                white-space: nowrap;
                font-size: 0.12rem;
                text-align: left;
                line-height: 0.15rem;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .keypal-plus {
    .break-down {
      padding: 0.78rem 0;
      .light {
        display: none;
      }
      .light-m {
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
      }
      .main-title {
        display: block;
        color: #fff;
      }
      .easy-and-safe {
        .title-wrap {
          max-width: 75%;
          border: none;
          padding: 0;
          .title {
            font-size: 0.4rem;
            line-height: normal;
          }
          .desc {
            margin-top: 0.21rem;
            font-size: 0.26rem;
            line-height: 0.31rem;
          }
        }
        .list {
          padding: 0 0.62rem;
          margin-top: 0.58rem;
          flex-wrap: wrap;
          justify-content: space-between;
          .easy-safe-item:not(:nth-child(3n + 1)) {
            margin-left: 0;
          }
          .easy-safe-item:nth-child(n + 4) {
            margin-top: 0rem;
          }
          .easy-safe-item:nth-child(n + 3) {
            margin-top: 1rem;
          }
        }
      }
      .product-specifications {
        .title-wrap {
          .title {
            font-size: 0.4rem;
          }
        }
        .container {
          flex-direction: column;
          .left-wrap {
            display: flex;
            justify-content: center;
            .keypal-plus-line {
              max-width: 80%;
            }
            .pc {
              display: none;
            }
            .mobile {
              display: block;
              .text {
                color: #fff;
                font-size: 0.16rem;
              }
              .text-title {
                font-size: 0.19rem;
                font-family: Bold;
                color: #00e6e3;
                line-height: 0.23rem;
              }
              .left-2-wrap {
                position: absolute;
                left: 6%;
                top: 46%;
                max-width: 1.46rem;
                &.english {
                  top: 42%;
                }
              }
              .left-3-wrap {
                position: absolute;
                left: 6%;
                top: 71%;
                max-width: 1.46rem;
                &.english {
                  top: 67%;
                }
              }
              .right-1-wrap {
                position: absolute;
                left: auto;
                right: 6%;
                top: 27%;
                max-width: 1.46rem;
                &.english {
                  top: 23%;
                }
                .text {
                  text-align: right;
                }
              }
              .right-2-wrap {
                left: auto;
                right: 6%;
                &.english {
                  top: 43%;
                }
                .text {
                  text-align: right;
                }
              }
              .right-3-wrap {
                left: auto;
                right: 6%;
                top: 64%;
                &.english {
                  top: 61%;
                }
                .text {
                  text-align: right;
                }
              }
              .center-wrap {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -10%;
                .text {
                  text-align: center;
                }
                .center {
                  font-size: 0.19rem;
                  font-family: Bold;
                  text-align: center;
                  color: #00e6e3;
                  line-height: 0.23rem;
                }
                .center-2 {
                  margin-top: 0.1rem;
                  line-height: 0.2rem;
                }
              }
            }
          }
          .specification-wrap {
            position: relative;
            padding: 0 0.5rem;
            margin: 0;
            margin-top: 1.5rem;
            .specification-list {
              .specification-item {
                width: 48%;
                border: none;
                background: none;
                padding: 0;
                &:nth-child(n + 3) {
                  margin-top: 0.6rem;
                }
                .title {
                  padding: 0;
                  font-size: 0.26rem;
                  margin-bottom: 0.17rem;
                  color: #00fff8;
                  font-family: Bold;
                }
                img {
                  width: 0.22rem;
                }
                span {
                  margin-left: 0.1rem;
                  font-size: 0.22rem;
                  line-height: 0.26rem;
                }
              }
            }
            .specification-list-2 {
              display: flex;
              margin-top: 0.6rem;

              .specification-item {
                width: 100%;
                &:nth-child(n + 2) {
                  margin-top: 0.6rem;
                }
              }
              .package {
                border: none;
                padding: 0;
                background: none;
                .title {
                  padding: 0;
                  font-size: 0.26rem;
                  color: #00fff8;
                  line-height: 0.31rem;
                }
                .text {
                  font-size: 0.22rem;
                  line-height: 0.26rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
