<template>
  <div class="keypal-buy-container">
    <div class="cover" @click.stop="closeKeypalBuy"></div>
    <div class="keypal-popout">
      <div class="tab-bar">
        <!--        <div class="split"></div>-->
        <div
          class="tab"
          @click="switchTab('tab1')"
          :class="{ 'no-select': dialogType === 1 }"
        >
          <div class="tab-container">
            <img src="../../assets/images/blockchain/youzan.png" />
            <span>{{ this.$t('blockchain.keypal.youzan') }}</span>
          </div>
          <div class="tab-line" v-show="tab.tab1"></div>
        </div>
        <div class="tab" @click="switchTab('tab2')" v-if="dialogType !== 1">
          <div class="tab-container">
            <img src="../../assets/images/blockchain/tb.png" />
            <span>{{ this.$t('blockchain.keypal.tb') }}</span>
          </div>
          <div class="tab-line" v-show="tab.tab2"></div>
        </div>
      </div>
      <p class="desc">
        {{
          tab.tab1
            ? this.$t('blockchain.keypal.youzanDesc')
            : this.$t('blockchain.keypal.tbDesc')
        }}
      </p>
      <div class="qr-container">
        <div v-if="dialogType === 1">
          <img
            src="../../assets/images/blockchain/youzanQRCode.png"
            v-show="tab.tab1"
          />
          <img
            src="../../assets/images/blockchain/tbQRCode.png"
            v-show="tab.tab2"
          />
        </div>
        <div v-if="dialogType === 2">
          <img
            src="../../assets/images/blockchain/youzanIronQRCode.png"
            v-show="tab.tab1"
          />
          <img
            src="../../assets/images/blockchain/tbIronQRCode.png"
            v-show="tab.tab2"
          />
        </div>
        <div v-if="dialogType === 3">
          <img
            src="../../assets/images/blockchain/youzanLeatherQRCode.png"
            v-show="tab.tab1"
          />
          <img
            src="../../assets/images/blockchain/tbLeatherQRCode.png"
            v-show="tab.tab2"
          />
        </div>
      </div>
      <a :href="tab1Url" v-if="dialogType === 1">
        <div class="btn" :class="tab.tab1 ? 'red' : 'orange'">
          {{ this.$t('blockchain.keypal.urlName') }}
        </div>
      </a>
      <a :href="tab1Icon" v-if="dialogType === 2">
        <div class="btn" :class="tab.tab1 ? 'red' : 'orange'">
          {{ this.$t('blockchain.keypal.urlName') }}
        </div>
      </a>
      <a :href="tab1Leather" v-if="dialogType === 3">
        <div class="btn" :class="tab.tab1 ? 'red' : 'orange'">
          {{ this.$t('blockchain.keypal.urlName') }}
        </div>
      </a>
    </div>
    <div
      class="keypal-popout-mobile"
      :class="{ 'one-select': dialogType === 1 }"
    >
      <p class="title">{{ this.$t('blockchain.keypal.buyWay') }}</p>
      <a
        class="youzan-container"
        :href="
          dialogType === 1
            ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2od5z9zzdfzkn?scan=1&activity=none&from=kdt&qr=directgoods_992648103&shopAutoEnter=1'
            : dialogType === 2
            ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2oi0qxzsshq13?scan=1&activity=none&from=kdt&qr=directgoods_992540647&shopAutoEnter=1'
            : 'https://shop95838799.m.youzan.com/wscgoods/detail/366w49k8dlkyf?scan=1&activity=none&from=kdt&qr=directgoods_963353425&shopAutoEnter=1'
        "
      >
        <img src="../../assets/images/blockchain/youzan.png" />
        <span>{{ this.$t('blockchain.keypal.youzan') }}</span>
        <span class="right">></span>
      </a>
      <div class="tb-container" @click="toTb" v-if="dialogType !== 1">
        <img src="../../assets/images/blockchain/tb.png" />
        <span>{{ this.$t('blockchain.keypal.tb') }}</span>
        <span class="right">></span>
      </div>
      <div class="cancel" @click.stop="closeKeypalBuy">
        {{ this.$t('blockchain.keypal.cancel') }}
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeypalBuy',
  data() {
    return {
      tab: {
        tab1: true,
        tab2: false,
      },
    }
  },
  props: {
    homeLang: {
      type: Object,
    },
    dialogType: {
      type: Number,
    },
  },
  computed: {
    tab1Url() {
      if (this.tab.tab1) {
        return 'https://shop95838799.m.youzan.com/wscgoods/detail/2od5z9zzdfzkn?scan=1&activity=none&from=kdt&qr=directgoods_992648103&shopAutoEnter=1'
      } else {
        return 'https://item.taobao.com/item.htm?ft=t&id=648607711132'
      }
    },
    tab1Icon() {
      if (this.tab.tab1) {
        return 'https://shop95838799.m.youzan.com/wscgoods/detail/2oi0qxzsshq13?scan=1&activity=none&from=kdt&qr=directgoods_992540647&shopAutoEnter=1'
      } else {
        return 'https://item.taobao.com/item.htm?ft=t&id=648608435027'
      }
    },
    tab1Leather() {
      if (this.tab.tab1) {
        return 'https://shop95838799.m.youzan.com/wscgoods/detail/366w49k8dlkyf?scan=1&activity=none&from=kdt&qr=directgoods_963353425&shopAutoEnter=1'
      } else {
        return 'https://item.taobao.com/item.htm?ft=t&id=648608459570'
      }
    },
  },
  created() {
    console.log(this.dialogType)
  },
  methods: {
    closeKeypalBuy() {
      this.$emit('setKeypalBuyVisible', false, 1)
    },
    switchTab(tab) {
      for (let item in this.tab) {
        this.tab[item] = false
      }
      this.tab[tab] = true
    },
    toTb() {
      if (this.dialogType === 1) {
        window.location = this.homeLang.keypal.urlTb.replace(
          'https://',
          'taobao://'
        )
        window.location = this.homeLang.keypal.urlTb
      } else if (this.dialogType === 2) {
        window.location = this.homeLang.keypal.urlTbIron.replace(
          'https://',
          'taobao://'
        )
        window.location = this.homeLang.keypal.urlTbIron
      } else if (this.dialogType === 3) {
        window.location = this.homeLang.keypal.urlTbLeather.replace(
          'https://',
          'taobao://'
        )
        window.location = this.homeLang.keypal.urlTbLeather
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 767px) {
  .keypal-buy-container {
    .keypal-popout-mobile {
      display: none;
    }
    .cover {
      z-index: 9;
      position: fixed;
      width: 100vw;
      height: 100vh;
      opacity: 0.6;
      background: #000000;
      left: 0;
      top: 0;
    }
    .keypal-popout {
      z-index: 19;
      width: 5.52rem;
      height: 6.48rem;
      background: #ffffff;
      border-radius: 0.08rem;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      .tab-bar {
        height: 0.96rem;
        position: relative;
        border-bottom: #eeeeee 1px solid;
        .split {
          height: 0.48rem;
          width: 1px;
          position: absolute;
          background: #eeeeee;
          left: 50%;
          top: 0.28rem;
        }
        .tab {
          width: 50%;
          float: left;
          cursor: pointer;
          &.no-select {
            width: 100%;
            .tab-line {
              display: none;
            }
          }
          .tab-container {
            height: 0.92rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.36rem;
            }
            span {
              font-size: 0.25rem;
              font-family: PingFangSC, PingFangSC-Regular;
              color: #333333;
              margin-left: 0.14rem;
            }
          }
          .tab-line {
            width: 1.62rem;
            height: 0.03rem;
            margin: 0 auto;
            background: #2761e7;
            border-radius: 0.02rem;
          }
        }
      }

      .desc {
        font-size: 0.23rem;
        font-family: PingFangSC, PingFangSC-Regular;
        text-align: center;
        color: #333333;
        line-height: 0.33rem;
        margin-top: 0.29rem;
      }
      .qr-container {
        width: 3.26rem;
        height: 3.26rem;
        background: rgba(224, 0, 0, 0.05);
        margin: 0.28rem auto 0.31rem;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          img {
            height: 3rem;
            width: 3rem;
            opacity: 1;
          }
        }
      }
      .btn {
        width: 3rem;
        height: 0.58rem;
        border-radius: 0.29rem;
        font-size: 0.21rem;
        font-family: PingFangSC, PingFangSC-Regular;
        color: #ffffff;
        text-align: center;
        line-height: 0.58rem;
        margin: 0 auto;
        cursor: pointer;
        &.red {
          background: #ee0000;
        }
        &.orange {
          background: #fd5900;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .keypal-buy-container {
    .keypal-popout {
      display: none;
    }
    .cover {
      z-index: 29;
      position: fixed;
      width: 100vw;
      height: 100vh;
      opacity: 0.6;
      background: #000000;
      left: 0;
      top: 0;
    }
    .keypal-popout-mobile {
      width: 100%;
      z-index: 39;
      height: 6rem;
      position: fixed;
      bottom: 0;
      background: rgb(246, 247, 249);
      &.one-select {
        height: 4.9rem;
      }
      .title {
        color: #111111;
        line-height: 1.1rem;
        font-size: 0.33rem;
        text-align: center;
      }
      .youzan-container,
      .tb-container {
        position: relative;
        background-color: white;
        width: 6.5rem;
        height: 1.1rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        border-radius: 0.04rem;
        img {
          height: 0.4rem;
          margin-left: 0.5rem;
        }
        span {
          color: rgb(47, 47, 47);
          font-size: 0.4rem;
          margin-left: 0.4rem;
        }
        .right {
          font-size: 0.33rem;
          float: right;
          right: 0.4rem;
          position: absolute;
        }
      }
      .tb-container {
        margin-top: 0.03rem;
      }
      .cancel {
        margin: 0.2rem auto 0;
        color: rgb(58, 58, 58);
        background-color: white;
        width: 6.5rem;
        height: 1.1rem;
        line-height: 1.1rem;
        text-align: center;
        border-radius: 0.04rem;
        font-size: 0.4rem;
      }
      .line {
        background-color: black;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 2.4rem;
        height: 0.1rem;
        border-radius: 0.05rem;
      }
    }
  }
}
</style>
