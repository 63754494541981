<template>
  <div class="download-button">
    <div class="button-content" @click="onDownload(button.url)">
      <div class="left">
        <div :class="[button.class]"></div>
        <span class="text">{{ button.platform }}</span>
      </div>
      <div v-if="button.children" class="right"></div>
    </div>
    <div class="space"></div>
    <div class="download-list" v-show="button.children">
      <div
        class="item"
        v-for="(item, index) in button.children"
        :key="index"
        @click="onDownload(item.url)"
      >
        <span class="text">{{ item.text }}</span>
        <div :class="{ line: index < button.children.length - 1 }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownLoadButton',
  props: {
    button: {
      type: Object,
    },
  },
  // created() {
  //   console.log('button', this.button)
  // },

  methods: {
    onDownload(url) {
      if (
        /Android|SymbianOS|iPhone|iPad|iPod|Windows Phone/i.test(
          navigator.userAgent
        )
      ) {
        this.$emit('onButton')
      } else {
        window.open(url, '_self')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.download-button {
  position: relative;
  margin-top: 0.2rem;
  cursor: pointer;

  .button-content {
    width: 1.78rem;
    height: 0.58rem;
    background: #ffffff;
    border: 0.01rem solid #afc7ff;
    border-radius: 0.08rem;
    padding: 0 0.16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
  }

  .download-list {
    position: absolute;
    display: none;
    width: 1.78rem;
    border-radius: 0.08rem;
    box-shadow: 0 0.02rem 0.1rem rgba(0, 0, 0, 0.15);
    background: #ffffff;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        margin: 0.18rem 0;
        font-size: 0.16rem;
        color: #333;
      }
      .line {
        width: 1.46rem;
        height: 0.01rem;
        background: #f2f2f2;
      }
    }
  }
}

@media screen and(min-width: 767px) {
  //定义一个循环  初始化i=0,循环体内i+1,条件i<10内 满足条件
  @for $i from 1 through 3 {
    //递归调用 达到循环的目的
    .download-button {
      .button-content {
        .left {
          .icon-#{i} {
            width: 0.44rem;
            height: 0.44rem;
            background: url('../../assets/images/firmwareupgrade/icon-off-#{$i}.png')
              no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .download-button:hover {
      .button-content {
        .left {
          .icon-#{i} {
            width: 0.44rem;
            height: 0.44rem;
            background: url('../../assets/images/firmwareupgrade/icon-on-#{$i}.png')
              no-repeat;
            background-size: contain;
          }
        }
      }
    }
    // .loop((@i+1)); //执行某个需要循环的方法
  }
  //初始化开始循环 不初始化 不会开始循环
  // .loop(1);
  .text {
    font-size: 0.18rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #fff;
  }
  .download-button {
    .button-content {
      width: 1.78rem;
      .left {
        span {
          color: #2761e7;
        }
      }
      .right {
        width: 0.1rem;
        height: 0.06rem;
        background: url('../../assets/images/firmwareupgrade/down.png')
          no-repeat;
        background-size: contain;
      }
    }
    .space {
      height: 0.2rem;
    }
  }
  .download-button:hover {
    .button-content {
      background: #2761e7;
      .left {
        span {
          color: #fff;
        }
      }
      .right {
        width: 0.1rem;
        height: 0.06rem;
        background: url('../../assets/images/firmwareupgrade/up.png') no-repeat;
        background-size: contain;
      }
    }
    .download-list {
      display: block;
      .item:hover {
        span {
          color: #2761e7;
        }
      }
    }
  }
}
@media screen and(max-width: 767px) {
  //定义一个循环  初始化i=0,循环体内i+1,条件i<10内 满足条件
  @for $i from 1 through 3 {
    //递归调用 达到循环的目的
    .download-button {
      .button-content {
        .left {
          .icon-#{i} {
            width: 0.64rem;
            height: 0.64rem;
            background: url('../../assets/images/firmwareupgrade/icon-m-#{$i}.png')
              no-repeat;
            background-size: contain;
          }
        }
      }
    }
    // .loop((@i+1)); //执行某个需要循环的方法
  }
  //初始化开始循环 不初始化 不会开始循环
  // .loop(1);
  .text {
    font-size: 0.18rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #fff;
  }
  .download-button {
    .button-content {
      width: 5.7rem;
      height: 0.88rem;
      border: 0.01rem solid #d7d7d7;
      justify-content: center;
      .left {
        span {
          font-size: 0.26rem;
          color: #d7d7d7;
        }
      }
      .right {
        margin-left: 0.2rem;
        width: 0.16rem;
        height: 0.09rem;
        background: url('../../assets/images/firmwareupgrade/down-m.png')
          no-repeat;
        background-size: contain;
      }
    }
    .space {
      display: none;
    }
  }
}
</style>
