<template>
  <div class="blockchain">
    <Header />
    <div class="content">
      <Blockchain />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/common/Footer.vue'
import Header from '../components/common/Header.vue'
import Blockchain from '../components/blockchain/Blockchain.vue'
export default {
  components: { Header, Blockchain, Footer },
}
</script>
<style lang="scss" scoped>
.blockchain {
  .content {
    max-width: 12rem;
  }
}
</style>
