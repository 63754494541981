<template>
  <div class="academy">
    <Header security />
    <div class="content">
      <div class="text title operation-title">{{ $t('academy.title_1') }}</div>
      <OperationAcademy />
      <div class="get-start">
        <div class="title">{{ $t('academy.title_2') }}</div>
        <div class="initialization-wrap d-flex">
          <div class="initialization">
            <div class="text d-flex ai-center">
              <span class="label-order">1</span>
              <span class="label">{{ $t('academy.get_start_desc_1') }}</span>
            </div>
            <div class="text desc-wrap">
              {{ $t('academy.get_start_desc_2') }}
              <img src="../assets/images/academy/ok.png" />
              {{ $t('academy.get_start_desc_3') }}
            </div>
          </div>
          <div class="initialization initialization-2">
            <div class="text d-flex ai-center">
              <span class="label-order">2</span>
              <span class="label">{{ $t('academy.get_start_desc_4') }}</span>
            </div>
            <div class="row">
              <div class="text desc-wrap">
                {{ $t('academy.get_start_desc_5') }}
                <img src="../assets/images/academy/right.png" />
                {{ $t('academy.get_start_desc_6') }}
              </div>
            </div>
            <div class="row">
              <div class="text desc-wrap">
                {{ $t('academy.get_start_desc_7') }}
                <img src="../assets/images/academy/Under.png" />
                {{ $t('academy.get_start_desc_8') }}
              </div>
            </div>
            <div class="row">
              <div class="text desc-wrap">
                {{ $t('academy.get_start_desc_9') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="create-wallet">
        <img class="create-wallet-img" :src="createWalletImg" />
        <img class="create-wallet-img-m" :src="createWalletMobileImg" />
        <div class="message-wrap">
          <MessageBox width200 :text="$t('academy.get_start_desc_10')" />
        </div>
        <div
          class="message-wrap message-wrap-m"
          :class="{ english: $i18n.locale === 'en' }"
        >
          <MessageBox bottom width300 :text="$t('academy.get_start_desc_10')" />
        </div>
        <div
          class="message-wrap message-wrap-2"
          :class="{ english: $i18n.locale === 'en' }"
        >
          <MessageBox bottom width300 :text="$t('academy.get_start_desc_11')" />
        </div>
      </div>
      <div class="connect-TokenPocket">
        <div class="title">{{ $t('academy.title_3') }}</div>
        <div class="text-wrap">
          <div class="text">{{ $t('academy.connect_desc_1') }}</div>
          <div class="text">
            {{ $t('academy.connect_desc_2') }}
            <img src="../assets/images/academy/create-wallet.png" />
            {{ $t('academy.connect_desc_3') }}
          </div>
          <div class="text">{{ $t('academy.connect_desc_4') }}</div>
          <div class="text">{{ $t('academy.connect_desc_4_2') }}</div>
        </div>
        <div class="text bold">{{ $t('academy.connect_desc_5') }}</div>
        <img class="connect-1" :src="connectImg1" />
        <img class="connect-1-mobile" :src="connectMobileImg1" />
        <div class="connect-2-wrap">
          <img class="connect-2" :src="connectImg2" />
          <img class="connect-2-mobile" :src="connectMobileImg2" />
          <div class="message-wrap message-wrap-2">
            <MessageBox bottom width300 :text="$t('academy.connect_desc_6')" />
          </div>
        </div>
      </div>
      <div class="connect-TokenPocket batch">
        <div class="title">{{ $t('academy.title_4') }}</div>
        <div class="text-wrap">
          <div class="text">{{ $t('academy.batch_desc_1') }}</div>
          <div class="text">{{ $t('academy.batch_desc_2') }}</div>
          <div class="text">{{ $t('academy.batch_desc_3') }}</div>
          <div class="text">{{ $t('academy.batch_desc_4') }}</div>
        </div>
        <div class="batch-wrap">
          <img class="batch-img" :src="batchImg" />
          <img class="batch-img-mobile" :src="batchMobileImg" />
          <div class="message-wrap" :class="{ english: $i18n.locale === 'en' }">
            <MessageBox
              bottom
              batch
              width300
              :text="$t('academy.batch_desc_5')"
            />
          </div>
          <div
            class="message-wrap message-wrap-2"
            :class="{ english: $i18n.locale === 'en' }"
          >
            <MessageBox bottom width300 :text="$t('academy.batch_desc_6')" />
          </div>
        </div>
      </div>
      <div class="connect-TokenPocket exchange">
        <div class="title">{{ $t('academy.title_5') }}</div>
        <div class="text-wrap">
          <div class="text">{{ $t('academy.exchange_desc_1') }}</div>
          <div class="text">{{ $t('academy.exchange_desc_2') }}</div>
          <!-- <div class="text">{{ $t('academy.exchange_desc_3') }}</div> -->
        </div>
        <div class="exchange-wrap">
          <img class="exchange-1" :src="exchangeImg1" />
          <img class="exchange-1-mobile" :src="exchangeMobileImg1" />
          <div class="message-wrap">
            <MessageBox width300 bottom :text="$t('academy.exchange_desc_3')" />
          </div>
          <div class="message-wrap message-wrap-m">
            <MessageBox width300 :text="$t('academy.exchange_desc_3')" />
          </div>
        </div>
        <img class="exchange-2" :src="exchangeImg2" />
        <img class="exchange-2-mobile" :src="exchangeMobileImg2" />
      </div>
      <!-- <AcademyTutorial /> -->
    </div>
    <Footer security />
  </div>
</template>

<script>
import AcademyTutorial from '../components/common/AcademyTutorial.vue';
import Footer from '../components/common/Footer.vue';
import Header from '../components/common/Header.vue';
import MessageBox from '../components/common/MessageBox.vue';
import OperationAcademy from '../components/common/OperationAcademy.vue';
export default {
  components: {
    Header,
    Footer,
    OperationAcademy,
    MessageBox,
    AcademyTutorial,
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    createWalletImg() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/zh/create-wallet-zh.png');
      } else {
        return require('../assets/images/academy/step/en/create-wallet.png');
      }
    },
    createWalletMobileImg() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/mobile/zh/create-wallet-zh-m.png');
      } else {
        return require('../assets/images/academy/step/mobile/en/create-wallet-m.png');
      }
    },
    connectImg1() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/zh/connect-1-zh.png');
      } else {
        return require('../assets/images/academy/step/en/connect-1.png');
      }
    },
    connectMobileImg1() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/mobile/zh/connect-1-zh-m.png');
      } else {
        return require('../assets/images/academy/step/mobile/en/connect-1-m.png');
      }
    },
    connectImg2() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/zh/connect-2-zh.png');
      } else {
        return require('../assets/images/academy/step/en/connect-2.png');
      }
    },
    connectMobileImg2() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/mobile/zh/connect-2-zh-m.png');
      } else {
        return require('../assets/images/academy/step/mobile/en/connect-2-m.png');
      }
    },
    batchImg() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/zh/batch-zh.png');
      } else {
        return require('../assets/images/academy/step/en/batch.png');
      }
    },
    batchMobileImg() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/mobile/zh/batch-zh-m.png');
      } else {
        return require('../assets/images/academy/step/mobile/en/batch-m.png');
      }
    },
    exchangeImg1() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/zh/exchange-1-zh.png');
      } else {
        return require('../assets/images/academy/step/en/exchange-1.png');
      }
    },
    exchangeMobileImg1() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/mobile/zh/exchange-1-zh-m.png');
      } else {
        return require('../assets/images/academy/step/mobile/en/exchange-1-m.png');
      }
    },
    exchangeImg2() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/zh/exchange-2-zh.png');
      } else {
        return require('../assets/images/academy/step/en/exchange-2.png');
      }
    },
    exchangeMobileImg2() {
      if (this.lang === 'zh') {
        return require('../assets/images/academy/step/mobile/zh/exchange-2-zh-m.png');
      } else {
        return require('../assets/images/academy/step/mobile/en/exchange-2-m.png');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.academy {
  background: #101010;
  .content {
    margin-top: 0.3rem;
  }
  .text {
    color: #fff;
    font-family: Light;
  }
  .title {
    opacity: 0.95;
    font-size: 0.32rem;
    text-align: left;
    color: #00f2ef;
    line-height: 0.39rem;
    font-family: Bold;
  }
  .get-start {
    margin-top: 1rem;
    .initialization {
      margin-top: 0.5rem;
      .label-order {
        width: 0.2rem;
        height: 0.2rem;
        line-height: 0.2rem;
        background: #00e6e3;
        border-radius: 50%;
        font-size: 0.12rem;
        text-align: center;
        color: #0b0b0b;
      }
      .label {
        margin-left: 0.06rem;
        font-size: 0.2rem;
        text-align: left;
        color: #ffffff;
      }
      .row {
        .desc-wrap {
          width: auto;
          img {
            width: 0.18rem;
            height: 0.18rem;
          }
        }
      }
      .desc-wrap {
        margin-top: 0.1rem;
        margin-left: 0.26rem;
        color: #ffffff;
        width: 3.3rem;
        line-height: 0.3rem;
        img {
          width: 0.24rem;
          height: 0.24rem;
          vertical-align: middle;
        }
      }
    }
    .initialization-2 {
      margin-left: 0.6rem;
    }
  }

  .create-wallet {
    position: relative;
    font-size: 0;
    .create-wallet-img {
      margin-top: 0.6rem;
      max-width: 10.75rem;
    }
    .create-wallet-img-m {
      display: none;
    }
    .message-wrap {
      position: absolute;
      left: 1.6rem;
      bottom: 0.2rem;
    }
    .message-wrap-m {
      display: none;
      white-space: pre-wrap;
    }
    .message-wrap-2 {
      left: 6.6rem;
      top: 0.25rem;
    }
  }

  .connect-TokenPocket {
    margin-top: 1.2rem;
    font-size: 0;
    .text-wrap {
      margin-top: 0.3rem;
      .text {
        line-height: 0.3rem;
        font-size: 0.14rem;
      }
      img {
        width: 0.24rem;
        height: 0.24rem;
        vertical-align: middle;
      }
    }
    .text {
      font-size: 0.12rem;
    }
    .bold {
      font-family: 'Bold';
      font-size: 0.16rem;
      line-height: 0.2rem;
      margin-top: 0.2rem;
    }

    .instructions {
      width: 0.18rem;
      height: 0.18rem;
      margin: 0.26rem 0.06rem 0 0.13rem;
    }
    .connect-1 {
      max-width: 8.39rem;
      margin-top: 0.4rem;
    }
    .connect-1-mobile {
      display: none;
    }
    .connect-2-wrap {
      position: relative;
      .connect-2 {
        margin-top: 0.3rem;
        max-width: 10.75rem;
      }
      .connect-2-mobile {
        display: none;
      }
      .message-wrap {
        position: absolute;
        top: 0;
        left: 9.73rem;
      }
    }
  }
  .batch {
    margin-top: 0.93rem;
    .text-wrap {
      margin-top: 0.3rem;
      .text {
        line-height: 0.3rem;
      }
    }
    .main-img {
      width: 1.3rem;
      height: 2.3rem;
    }
    .batch-wrap {
      position: relative;
      margin-top: 0.6rem;
      .batch-img {
        width: 10.75rem;
      }
      .batch-img-mobile {
        display: none;
      }
      .message-wrap {
        position: absolute;
        left: 1rem;
        top: -0.35rem;
        &.english {
          left: 0;
        }
      }
      .message-wrap-2 {
        position: absolute;
        left: 6.5rem;
        top: -0.35rem;
        &.english {
          left: 6.5rem;
        }
      }
    }
  }
  .exchange {
    .exchange-wrap {
      position: relative;
      margin-top: 0.75rem;
      .exchange-1 {
        max-width: 6.03rem;
      }
      .exchange-1-mobile {
        display: none;
      }
      .message-wrap {
        position: absolute;
        left: 4.3rem;
        top: -0.35rem;
      }
      .message-wrap-m {
        display: none;
      }
    }
    .exchange-2 {
      margin-top: 0.3rem;
      width: 8.4rem;
    }
    .exchange-2-mobile {
      display: none;
    }
  }
  .academy-tutorial {
    margin-top: 1.3rem;
  }
}
@media screen and (max-width: 767px) {
  .academy {
    .title {
      padding: 0;
      font-size: 0.36rem;
    }
    .get-start {
      .initialization-wrap {
        flex-direction: column;
      }
      .initialization {
        margin-top: 0.3rem;
        .row {
          .desc-wrap {
            img {
              width: 0.32rem;
              height: 0.32rem;
            }
          }
        }
        .desc-wrap {
          width: auto;
          line-height: 0.42rem;
          margin-left: 0.52rem;
          font-size: 0.22rem;
          img {
            width: 0.36rem;
            height: 0.36rem;
          }
        }
        .label-order {
          width: 0.32rem;
          height: 0.32rem;
          line-height: 0.32rem;
          font-size: 0.19rem;
        }
        .label {
          margin-left: 0.2rem;
          font-size: 0.28rem;
          font-family: 'Bold';
        }
      }
      .initialization-2 {
        margin-left: 0;
      }
    }
    .create-wallet {
      .create-wallet-img {
        display: none;
      }
      .create-wallet-img-m {
        display: block;
        width: 100%;
        margin-top: 0.4rem;
      }
      .message-wrap {
        display: none;
      }
      .message-wrap-m {
        display: block;
        top: 33%;
        left: 0.1rem;
        &.english {
          top: 31%;
        }
      }
      .message-wrap-2 {
        display: block;
        left: auto;
        right: 0;
        top: 52%;
        &.english {
          top: 50%;
        }
      }
    }
    .create-wallet-mobile-wrap {
      display: block;
    }
    .connect-TokenPocket {
      .text-wrap {
        .text {
          font-size: 0.22rem;
          line-height: 0.32rem;
        }
        :nth-child(n + 2) {
          margin-top: 0.2rem;
        }
        img {
          width: 0.4rem;
          height: 0.4rem;
        }
      }
      .bold {
        margin-top: 0.4rem;
        font-size: 0.24rem;
        line-height: 0.38rem;
      }
      .connect-1 {
        display: none;
      }
      .connect-1-mobile {
        display: block;
        width: 100%;
        margin-top: 0.4rem;
      }
      .connect-2 {
        display: none;
      }
      .connect-2-wrap {
        margin-top: 0.6rem;
        .connect-2-mobile {
          display: block;
          width: 100%;
        }
        .message-wrap {
          left: 2%;
          top: 68%;
          &.english {
            top: 70%;
          }
        }
      }
    }
    .batch {
      margin-top: 0.8rem;
      .batch-wrap {
        margin-top: 0.4rem;
        .batch-img {
          display: none;
        }
        .batch-img-mobile {
          display: block;
          width: 100%;
        }
        .message-wrap {
          left: auto;
          right: 0;
          top: -1%;
          &.english {
            left: auto;
            top: 0%;
          }
        }
        .message-wrap-2 {
          left: auto;
          right: 0;
          top: 66%;
          &.english {
            left: auto;
            top: 67%;
          }
        }
      }
    }
    .exchange {
      .text-wrap {
        :nth-child(n + 2) {
          margin-top: 0;
        }
      }
      .exchange-wrap {
        .exchange-1 {
          display: none;
        }
        .exchange-1-mobile {
          display: block;
          width: 100%;
        }
        .message-wrap {
          display: none;
        }
        .message-wrap-m {
          display: block;
          position: absolute;
          left: 0%;
          top: 60%;
        }
      }
      .exchange-2 {
        display: none;
      }
      .exchange-2-mobile {
        display: block;
        width: 100%;
        margin-top: 0.4rem;
      }
    }
  }
}
</style>
