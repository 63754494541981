<template>
  <div class="operation d-flex flex-column ai-center">
    <img class="main" src="../../assets/images/academy/keypal-line.png" />
    <div
      class="text left-1"
      v-if="$i18n.locale === 'en'"
      :class="{ english: $i18n.locale === 'en' }"
    >
      <div class="top-wrap">
        <span>Aluminum alloy shell</span>
        <span>Protect the wallet from damage</span>
      </div>
    </div>
    <div class="text left-1" v-else>
      {{ $t('academy.operation_desc_1') }}
    </div>
    <div
      class="text left-1 right-1"
      v-if="$i18n.locale === 'en'"
      :class="{ english: $i18n.locale === 'en' }"
    >
      <div class="top-wrap">
        <span>2-inch display</span>
        <span>To show the related transaction</span>
      </div>
    </div>
    <div class="text left-1 right-1" v-else>
      {{ $t('academy.operation_desc_6') }}
    </div>
    <div class="text left-3" :class="{ english: $i18n.locale === 'en' }">
      <p>{{ $t('academy.operation_desc_2') }}</p>
      <p>{{ $t('academy.operation_desc_3') }}</p>
    </div>
    <div class="text left-4" :class="{ english: $i18n.locale === 'en' }">
      <p>{{ $t('academy.operation_desc_4') }}</p>
      <p>{{ $t('academy.operation_desc_5') }}</p>
    </div>
    <div class="text right-2" :class="{ english: $i18n.locale === 'en' }">
      <p>{{ $t('academy.operation_desc_7') }}</p>
      <p>{{ $t('academy.operation_desc_8') }}</p>
    </div>
    <div
      class="text left-3 right-3"
      :class="{ english: $i18n.locale === 'en' }"
    >
      <p>{{ $t('academy.operation_desc_9') }}</p>
      <p>{{ $t('academy.operation_desc_10') }}</p>
    </div>
    <div
      class="text left-4 right-4"
      :class="{ english: $i18n.locale === 'en' }"
    >
      <p>{{ $t('academy.operation_desc_11') }}</p>
      <p>{{ $t('academy.operation_desc_12') }}</p>
    </div>
    <div class="center">
      {{ $t('academy.operation_desc_13') }}
    </div>
    <div class="center-2">
      {{ $t('academy.operation_desc_14') }}
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.operation {
  position: relative;
  margin-top: 0.8rem;
  .main {
    width: 4.96rem;
  }
  .text {
    position: absolute;
    font-size: 0.12rem;
    color: #fff;
    font-family: Light;
  }
  .center {
    margin-top: 0.05rem;
    font-size: 0.16rem;
    transform: translateX(0);
    line-height: 0.2rem;
    color: #00e6e3;
    font-family: Bold;
  }
  .center-2 {
    margin-top: 0.05rem;
    font-size: 0.16rem;
    color: #fff;
    font-family: Light;
  }
  .top-wrap {
    display: flex;
    flex-direction: column;
  }
  .left-1 {
    width: 2rem;
    left: 2.2rem;
    top: 0.05rem;
    text-align: right;
    font-size: 0.12rem;
    &.english {
      top: -0.1rem;
    }
  }
  .right-1 {
    left: 8rem;
    text-align: left;
  }
  .right-2 {
    top: 1rem;
    left: 8rem;
    width: 2.5rem;
    :nth-child(1) {
      font-size: 0.16rem;
      font-family: 'Bold';
      color: #00e6e3;
    }
  }
  .left-3 {
    top: 2.25rem;
    left: 2.2rem;
    text-align: right;
    :nth-child(1) {
      font-size: 0.16rem;
      font-family: 'Bold';
      color: #00e6e3;
    }
    &.english {
      width: 2rem;
      top: 2.1rem;
    }
  }
  .right-3 {
    left: 8rem;
    top: 2.25rem;
    // width: 2.5rem;
    text-align: left;
    &.english {
      width: auto;
      top: 2.25rem;
    }
  }
  .left-4 {
    top: 3.5rem;
    left: 2.2rem;
    text-align: right;
    width: 2rem;
    :nth-child(1) {
      font-size: 0.16rem;
      font-family: 'Bold';
      color: #00e6e3;
    }
  }
  .right-4 {
    left: 8rem;
    top: 3.35rem;
    text-align: left;
    &.english {
      width: 2.5rem;
    }
  }
}
@media screen and (max-width: 767px) {
  .operation {
    margin-top: 1.4rem;
    .main {
      width: 88%;
    }
    .text {
      font-size: 0.16rem;
      line-height: 0.22rem;
    }
    .center {
      font-size: 0.19rem;
    }
    .center-2 {
      margin-top: 0.09rem;
    }
    .top-wrap {
      display: inline-block;
    }
    .left-1 {
      max-width: 1.6rem;
      top: -3%;
      left: 0.1rem;
      font-size: 0.16rem;
      text-align: left;
      &.english {
        top: -6%;
      }
    }
    .right-1 {
      left: auto;
      right: 0.1rem;
      text-align: right;
    }
    .right-2 {
      left: auto;
      right: 0.1rem;
      top: 15%;
      text-align: right;
      max-width: 1.6rem;
      :nth-child(1) {
        font-size: 0.19rem;
        margin-bottom: 0.05rem;
      }
      &.english {
        top: 16%;
      }
    }
    .left-3 {
      top: 41%;
      left: 0.1rem;
      text-align: left;
      width: 2rem;
      :nth-child(1) {
        font-size: 0.19rem;
        margin-bottom: 0.05rem;
      }
      &.english {
        top: 41%;
      }
    }
    .right-3 {
      left: auto;
      right: 0.1rem;
      text-align: right;
      &.english {
        width: 2rem;
      }
    }
    .left-4 {
      left: 0.1rem;
      top: 69%;
      text-align: left;
      :nth-child(1) {
        font-size: 0.19rem;
        margin-bottom: 0.05rem;
      }
      &.english {
        top: 66%;
      }
    }
    .right-4 {
      top: 66%;
      left: auto;
      right: 0.1rem;
      text-align: right;
      // max-width: 2.1rem;
      width: 2.1rem;
      &.english {
        top: 63%;
      }
    }
  }
}
</style>
