import { render, staticRenderFns } from "./KeyPalPlus.vue?vue&type=template&id=890d440a&scoped=true&"
import script from "./KeyPalPlus.vue?vue&type=script&lang=js&"
export * from "./KeyPalPlus.vue?vue&type=script&lang=js&"
import style0 from "./KeyPalPlus.vue?vue&type=style&index=0&id=890d440a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "890d440a",
  null
  
)

export default component.exports