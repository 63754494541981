<template>
  <div class="safe-box">
    <Header isSafeBox />
    <VideoAnimate />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import VideoAnimate from '../components/animate/VideoAnimate.vue';
import Footer from '../components/common/Footer.vue';
import Header from '../components/common/Header.vue';

export default {
  components: { Header, Footer, VideoAnimate },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>
