<template>
  <div class="academy-tutorial content d-flex jc-between">
    <div class="tutorial-list">
      <div
        class="tutorial-item"
        :class="{ active: curIndex === index }"
        v-for="(item, index) in tutorialList"
        :key="index"
        @click="onTutorial(index)"
      >
        <div class="text title d-flex ai-center">
          <div class="triangle" :class="{ active: curIndex === index }"></div>
          <span>{{ item.title }}</span>
        </div>
        <div class="text desc">{{ item.desc }}</div>
      </div>
    </div>
    <!-- <div class="video-box">
      <img src="../../assets/images/academy/iPhone_1.png" />
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      curIndex: 0,
    };
  },
  computed: {
    tutorialList() {
      return [
        {
          title: this.$t('academy.tutorial_title_1'),
          desc: this.$t('academy.tutorial_desc_1'),
        },
        {
          title: this.$t('academy.tutorial_title_2'),
          desc: this.$t('academy.tutorial_desc_2'),
        },
        {
          title: this.$t('academy.tutorial_title_3'),
          desc: this.$t('academy.tutorial_desc_3'),
        },
        {
          title: this.$t('academy.tutorial_title_4'),
          desc: this.$t('academy.tutorial_desc_4'),
        },
        {
          title: this.$t('academy.tutorial_title_5'),
          desc: this.$t('academy.tutorial_desc_5'),
        },
      ];
    },
  },
  methods: {
    onTutorial(index) {
      this.curIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.academy-tutorial {
  .tutorial-list {
    .tutorial-item {
      cursor: pointer;
      &:nth-child(n + 2) {
        margin-top: 0.4rem;
      }
      .title {
        .triangle {
          border-left: 0.07rem solid #00fff8;
          visibility: hidden;
          &.active {
            visibility: visible;
          }
        }
        span {
          margin-left: 0.11rem;
          font-size: 0.18rem;
          text-align: left;
          color: #ffffff;
          font-family: 'Bold';
          line-height: 0.21rem;
        }
      }
      .desc {
        margin-left: 0.18rem;
        margin-top: 0.08rem;
        font-size: 0.14rem;
        font-weight: 400;
        text-align: left;
        color: #fff;
        line-height: 0.17rem;
        visibility: hidden;
      }
      &.active {
        .title {
          span {
            color: #00fff8;
          }
        }
        .desc {
          color: #00fff8;
          visibility: visible;
        }
      }
    }
  }
  .video-box {
    img {
      width: 2rem;
    }
  }
}
@media screen and (max-width: 767px) {
  .academy-tutorial {
    padding: 0;
    .tutorial-list {
      .tutorial-item {
        .title {
          .triangle {
            border-left: 0.09rem solid #00fff8;
            border-top: 0.07rem solid transparent;
            border-bottom: 0.07rem solid transparent;
            visibility: hidden;
            &.active {
              visibility: visible;
            }
          }
          text-align: left;
          padding: 0;
          span {
            margin-left: 0.15rem;
            font-size: 0.26rem;
            line-height: 0.31rem;
          }
        }
        .desc {
          margin-top: 0.11rem;
          font-size: 0.22rem;
          margin-left: 0.22rem;
          line-height: 0.26rem;
        }
      }
    }
  }
}
</style>
