<template>
  <div class="operation d-flex flex-column ai-center">
    <img class="main" src="../../assets/images/keypal-plus/keypal-line.png" />
    <div
      class="text left-1"
      :class="{ english: $i18n.locale === 'en' }"
      v-if="$i18n.locale === 'en'"
    >
      <div class="top-wrap">
        <span>Aluminum alloy shell</span>
        <span>Protect the wallet from damage</span>
      </div>
    </div>
    <div class="text left-1" v-else>
      {{ $t('keypal.detail_left_1') }}
    </div>
    <!-- <div class="text left-1" :class="{ english: $i18n.locale === 'en' }">
      {{ $t('keypal.detail_left_1') }}
    </div> -->
    <div
      class="text left-1 right-1"
      :class="{ english: $i18n.locale === 'en' }"
      v-if="$i18n.locale === 'en'"
    >
      <div class="top-wrap">
        <span>2-inch display</span>
        <span>To show the related transaction</span>
      </div>
    </div>
    <div class="text left-1 right-1" v-else>
      {{ $t('keypal.detail_right_1') }}
    </div>
    <!-- <div
      class="text left-1 right-1"
      :class="{ english: $i18n.locale === 'en' }"
    >
      {{ $t('keypal.detail_right_1') }}
    </div> -->
    <div class="text left-3">
      {{ $t('keypal.detail_left_2') }}
    </div>
    <div class="text left-4">
      {{ $t('keypal.detail_left_3') }}
    </div>
    <div class="text right-2">
      {{ $t('keypal.detail_right_2') }}
    </div>
    <div class="text left-3 right-3">
      {{ $t('keypal.detail_right_3') }}
    </div>
    <div class="text left-4 right-4">
      {{ $t('keypal.detail_right_4') }}
    </div>
    <div class="title center">
      {{ $t('keypal.detail_center') }}
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.operation {
  position: relative;
  margin-top: 0.8rem;
  .main {
    width: 4.96rem;
  }
  .text {
    position: absolute;
    font-size: 0.16rem;
    font-family: 'Bold';
  }
  .center {
    margin-top: 0.05rem;
    font-size: 0.16rem;
    transform: translateX(0.08rem);
    line-height: 0.2rem;
  }
  .top-wrap {
    display: flex;
    flex-direction: column;
  }
  .left-1 {
    max-width: 2rem;
    left: 2.2rem;
    top: 0.25rem;
    text-align: right;
    font-size: 0.12rem;
    color: #666666;
    font-family: Inter-Regular;
  }
  .right-1 {
    left: 8rem;
    top: 0.25rem;
    text-align: left;
    font-family: Inter-Regular;
  }
  .right-2 {
    top: 1.6rem;
    left: 8rem;
  }
  .left-3 {
    top: 2.6rem;
    left: 3rem;
  }
  .right-3 {
    left: 8rem;
  }
  .left-4 {
    top: 3.6rem;
    left: 3rem;
  }
  .right-4 {
    left: 8rem;
  }
}
@media screen and (max-width: 767px) {
  .operation {
    margin-top: 1.4rem;
    .main {
      width: 85%;
    }
    .text {
      font-size: 0.19rem;
    }
    .center {
      font-size: 0.19rem;
    }
    .top-wrap {
      display: inline-block;
    }
    .left-1 {
      max-width: 1.6rem;
      top: 3%;
      left: 7%;
      font-size: 0.16rem;
      text-align: left;
      &.english {
        top: 0%;
      }
    }
    .right-1 {
      left: auto;
      right: 7%;
      text-align: right;
    }
    .right-2 {
      left: auto;
      right: 7%;
      top: 32%;
    }
    .left-3 {
      top: 52%;
      left: 7%;
    }
    .right-3 {
      left: auto;
      right: 7%;
    }
    .left-4 {
      top: 72%;
      left: 7%;
    }
    .right-4 {
      left: auto;
      right: 7%;
    }
  }
}
</style>
