<template>
  <div
    class="security-item"
    :class="{ 'width-half': isHalf, 'flex-start': !isHalf }"
  >
    <img :src="sItem.img" :class="{ big: !isHalf }" />
    <div class="detail">
      <div class="text">{{ sItem.desc_1 }}</div>
      <div class="text">{{ sItem.desc_2 }}</div>
      <div class="text">{{ sItem.desc_3 }}</div>
      <div class="text">{{ sItem.desc_4 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sItem: {
      type: Object,
    },
    isHalf: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.security-item {
  display: flex;
  align-items: center;
  background: linear-gradient(
    135deg,
    rgba(41, 128, 254, 0.2),
    rgba(41, 128, 254, 0.05) 18%,
    rgba(41, 128, 254, 0.05)
  );
  border: 0.01rem solid rgba(41, 128, 254, 0.5);
  border-radius: 0.12rem;
  padding-right: 0.3rem;
  &.width-half {
    width: 49%;
    padding: 0.26rem 0.3rem;
  }

  img {
    width: 0.9rem;
    height: 0.9rem;
    &.big {
      width: 1.46rem;
      height: 1.4rem;
    }
  }
  .detail {
    margin-left: 0.3rem;
  }
  .text {
    font-size: 0.14rem;
    color: #ffffff;
    line-height: 0.23rem;
    font-family: Light;
    // &:nth-child(n + 2) {
    //   margin-top: 0.08rem;
    // }
  }
}
@media screen and (max-width: 767px) {
  .security-item {
    padding: 0.3rem 0.3rem 0.3rem 0;
    &.width-half {
      width: 100%;
    }
    &.flex-start {
      align-items: flex-start;
    }
    .detail {
      .text {
        line-height: 0.36rem;
        font-size: 0.22rem;
        // &:nth-child(n + 2) {
        //   margin-top: 0;
        // }
      }
    }
  }
}
</style>
